.pdf-total-listing {
  min-height: 2500px;
  width: 100%;
  background-color: #fcfcfc;
  color: rgba(var(--background), 1);
  display: none;
  overflow: hidden;
  position: fixed;
  left: 0;
  top: 0;
  z-index: -1;
  //esi dnes kereva pdf i ktor@

  //display: block;
  //position: absolute;
  //left: 0;
  //top: 100%;
  //height: 100% !important;
  //width: 100% !important;
  //top: 0;
  //z-index: 999;
  //overflow: auto;

  > div {
    min-width: 1780px;
  }

  // Header START
  .header {
    height: 148px;
  }

  .header-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 148px;
    padding: 0 40px;
    position: relative;
  }

  .user-name-column {
    display: block;
    white-space: nowrap;
  }

  .logo-img {
    width: auto;
    max-width: unset;
    min-height: 7rem;
    max-height: 8.0rem;
    height: unset;
  }

  .avatar-column {
    width: 8rem;
    height: 8rem;
    margin-left: 3.2rem;
    & .avatar-img.placeholder{
      font-size: 4.6rem;
    }
  }

  .user-name {
    font-size: 2.4rem;
    line-height: 2.8rem;
  }

  .user-info {
    font-size: 2rem;
    line-height: 2.6rem;
  }

  .add-preview-hide-mobile {
    display: none !important;
  }

  // Header END
  .footer {
    padding: 4.5rem 0;
    text-align: left;

    .container {
      max-width: 100%;
    }
  }

  .copyright-text-info {
    font-size: 1.8rem;
    line-height: 2.2rem;
  }

  .copyright-text-tm {
    transform: scale(1.3);
  }

  .pie-chart-info-container {
    white-space: nowrap;
  }

  .reports-content-table-column {
    white-space: nowrap;
  }

  .map-chart-table-row {
    height: 6rem;
    border: none !important;

    &.head {
      height: 8rem;
      background-color: #ffffff;

      .map-chart-table-text {
        font-size: 2.2rem;
        line-height: 2.6rem;
        font-weight: bold;
        color: rgba(var(--background), 1);
        text-transform: capitalize;
        white-space: nowrap;
      }
    }

    &:nth-child(even) {
      background-color: rgba(#100B28, 0.02);
    }
  }

  .map-chart-table-text {
    font-size: 2rem;
  }

  .map-chart-row {
    height: auto;
    grid-column: span 2;
    padding-bottom: 0;
    margin-bottom: 4rem;
  }

  .map-chart-content {
    padding-bottom: 0;
  }

  .map-chart-dots-mobile {
    display: none !important;
  }

  .pie-chart-info-title {
    font-size: 5.4rem;
    line-height: 6.8rem;
    letter-spacing: 0.81px;
    font-weight: bold;
  }

  .list-item {
    height: auto;
    margin-bottom: 2.6rem;
    padding-right: 3.8rem;
  }

  .pie-chart-info-subtitle {
    font-size: 2rem;
    line-height: 2.4rem;
    color: rgba(#100B28, 0.72);
  }

  .list-item-info {
    font-size: 2.2rem;
    line-height: 2.8rem;
  }

  .traffic-card-head,
  .map-chart-head {
    display: flex;
    justify-content: center;
    flex-direction: column;
    height: 174px;
    padding: 2.5rem 4rem 3rem 4rem !important;
  }

  .map-chart-dots-mobile {
    display: none;
  }

  .map-chart-table {
    display: flex;
    padding-right: 4rem;
  }


  .map-chart-table-cs {
    &.sticky {
      min-width: 31% !important;
      width: auto !important;
      position: static;

      .map-chart-table-row {
        padding-left: 4rem;
      }
    }

    &.full {
      text-align: center;

      .map-chart-table-row {
        padding-right: 0;
      }
    }
  }

  .area {
    display: flex;
    align-items: flex-end;

    div {
      width: 100% !important;
    }

    .highcharts-xaxis-labels {
      //transform: translate(0, 14px);

      text {
        //font-size: 14px !important;
      }
    }
  }


  .your-ad-details-head-address {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    word-break: break-word;
  }

  .empty-icon {
    display: block;
    //background-image: url("../images/inbox.svg");
    width: 50px;
    height: 50px;
    background-repeat: no-repeat;
    background-size: contain;
    filter: contrast(0.1);


    // need for a display icon 
    // &:before {
    //   display: none;
    // }
  }

  .empty-text {
    white-space: nowrap;
  }

  .traffic-card {
    height: auto;
    display: grid;
    grid-template-rows: auto 11fr;
  }

  .pie-chart {
    width: 25rem;
    height: 25rem;
  }

  .reports-head {
    height: 174px;
    padding: 2.5rem 4rem;
  }

  .reports-content {
    position: relative;

    .empty-card {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
    }
  }

  .reports-content-table-row {
    height: 5.5rem;
    margin-bottom: 0;
    padding: 0 6.4rem;

    &:nth-child(even) {
      background-color: rgba(#100B28, 0.02);
    }

    &.head {
      height: 8rem;
      background-color: #ffffff;
      margin-bottom: 0;
      border-bottom: 0;

      .reports-content-table-text {
        font-size: 2.2rem;
        line-height: 2.6rem;
        font-weight: bold;
        color: rgba(var(--background), 1);
        text-transform: capitalize;
        white-space: nowrap;
      }
    }
  }

  .reports-content-table-total {
    padding: 2rem 6.4rem;
  }

  .reports-content-table-total-text {
    font-size: 1.8rem;
    line-height: 2.2rem;
    font-weight: bold;
    color: rgba(var(--background), 1);
  }

  .reports-content-table-text {
    font-size: 2rem;
    white-space: nowrap;
  }

  .reports-content-table-scrollable {
    max-height: initial;
    height: auto;
    overflow: unset;
    border-bottom: 0;
  }

  .reports-head-typ-text {
    font-size: 22px;
    line-height: 28px;
  }

  .traffic-card-body {
    min-width: 0;
  }
  .pie-list,
  .list-item-title{
    white-space: unset;
    overflow: unset;
    text-overflow: unset;
    word-break: break-word;
  }
  .list-item-count{
    white-space: nowrap;
  }
  .list-item-title {
    flex: 1;
  }

  .traffic-card-head-title,
  .reach-and-clicks-title {
    font-size: 36px;
    line-height: 46px;
    margin-bottom: 10px;
  }

  .traffic-card-head-subtitle,
  .reach-and-clicks-text {
    font-size: 22px;
    line-height: 36px;
    color: rgba(#100B28, 0.64);
    padding: 0;
  }

  .map-chart-content {
    display: flex;
  }

  .reports-row {
    min-height: 44rem;
    height: auto;
    grid-template-columns: 1fr 1fr;
    grid-gap: 4rem;
  }

  .reports-column {
    display: grid;
    grid-template-rows: auto 11fr;
    max-height: 100%;

    &:first-child {
      .reports-content {
        align-items: flex-end;
      }
    }
  }

  .reports-content {
    display: flex;
    background-color: #ffffff;
  }

  .reports-head {
    background-color: #ffffff;
  }

  .map-chart-content-column {
    background-color: #ffffff;
    min-height: 48rem;

    &.chart-column {
      flex: 1;
      height: 100%;
      padding: 4.8rem 5.4rem 3.4rem 4.8rem;
    }
  }

  .map-chart {
    svg {
      width: 100%;
      height: 100%;
    }

    > div {
      > div {
        height: 100% !important;
      }
    }

  }

  .map-chart-info {
    font-size: 1.8rem;
    line-height: 2.2rem;
    padding-left: 5rem;
    padding-bottom: 3rem;
  }

  .staked-bar-chart {
    height: 34rem;
    padding-bottom: 3.2rem;
  }
  .map-chart {
    height: 34rem;
  }
  .reports-head-column {
    position: static;
  }

  .reports-head-typ {
    grid-auto-flow: column;
    grid-gap: 3.2rem;
  }

  .staked-bar-chart {
    padding: 0 4rem 4rem 4rem;
  }

  .highcharts-xaxis-labels {
    text {
      //font-size: 20px !important;
      fill: rgba(var(--background), 1) !important;
    }
  }

  .highcharts-yaxis-labels {
    text {
      //font-size: 20px !important;
      fill: rgba(var(--background), 1) !important;
    }
  }

  // Your Ad Details PDF START
  &.your-ad {
    width: 100%;

    .container {
      max-width: 100%;
    }
  }

  .your-ad-details-head {
    padding: 0;
  }

  .your-ad-details-head-inner {
    display: flex;
  }

  .your-ad-details-head-address {
    font-size: 4rem;
    line-height: 5.1rem;
    margin-bottom: 1.6rem;
  }

  .your-ad-details-head-inner-column {
    &:first-child {
      margin-bottom: 0;
    }
  }

  .your-ad-details-head-info {
    white-space: nowrap;
    display: inline-flex;
  }

  .your-ad-details-head-info-column {
    flex: 0 0 auto;
    width: auto !important;
    min-width: 0 !important;

    &:first-child {
      margin-bottom: 0;
    }

    &:not(:last-child) {
      margin-right: 10rem;
    }
  }

  .your-ad-details-head-info-title {
    font-size: 2rem;
    line-height: 2.4rem;
  }

  .your-ad-details-head-info-title-text {
    font-size: 2.4rem;
    line-height: 2.8rem;
    white-space: nowrap;
  }

  .your-ad-details-head-buttons {
    display: none;
  }

  .your-ad-details-info-row {
    margin-bottom: 4rem;
    grid-template-columns: repeat(auto-fit, minmax(25rem, 1fr)) !important;
    grid-gap: 4rem;
    justify-content: unset;
  }

  .reach-and-clicks {
    display: flex;
  }

  .your-ad-details-info-column {
    display: flex;
    padding: 3rem 4rem;
    min-height: 15rem;
  }

  .your-ad-details-min-info-content {
    opacity: 1;
  }

  .your-ad-details-min-info-icon {
    display: none;
  }

  .your-ad-details-min-info {
    position: static;
    display: block;
  }

  .your-ad-details-min-info-content {
    position: static;
    display: block;
    transform: none;
    background: none;
    text-align: left;
    padding: 2rem 0 0 0;

    &:before {
      display: none;
    }
  }

  .your-ad-details-min-title {
    font-size: 3rem;
    line-height: 3.8rem;
    margin-bottom: 1.3rem;
    font-weight: bold;
  }

  .your-ad-details-point {
    font-size: 4.2rem;
    line-height: 5.3rem;
    padding-top: 0;
  }

  .your-ad-details-min-info-text {
    color: rgba(#100B28, 0.64);
    letter-spacing: 0;
    font-size: 2rem;
    line-height: 3.2rem;
    text-align: left;
    word-break: break-word;
    white-space: unset;

    > span {
      display: initial;
      margin: 0 2px;
    }
  }

  // Your Ad Details PDF END
}


.pdf-main {
  width: 100%;
}

.pdf-footer {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 44px;
  text-transform: capitalize;
  height: 200px;
}

.pdf-container {
  margin: 0 auto;
  padding: 0 40px;
}

// pdf Header START


//.pdf-logo-block {
//  flex: 0 0 auto;
//}
//
//.pdf-logo-img {
//  display: block;
//  flex: 0 0 auto;
//  object-fit: contain;
//  object-position: left;
//  height: 70px;
//}
//
//.pdf-user-block {
//  display: flex;
//  align-items: center;
//}
//
//.pdf-user-name-column {
//  text-align: right;
//}
//
//.pdf-user-name {
//  font-size: 24px;
//  line-height: 28px;
//  color: rgba(var(--background), 1);
//  font-weight: 600;
//  text-transform: capitalize;
//}
//
//.pdf-avatar-column {
//  width: 80px;
//  height: 80px;
//  border-radius: 50%;
//  overflow: hidden;
//  margin-left: 32px;
//}
//
//.pdf-user-info {
//  font-size: 20px;
//  line-height: 26px;
//  color: rgba(var(--brand-color), 1);
//  text-transform: capitalize;
//}
//
//.pdf-avatar-img {
//  display: block;
//  width: 100%;
//  height: 100%;
//  object-fit: cover;
//}

// pdf Header END


// pdf your listing analytics row START
.pdf-your-listing-analytics-row {
  display: flex;
  align-items: center;
  width: 100%;
  height: 230px;
  padding: 20px 40px;
  background-color: rgba(var(--background), 1);
}

.pdf-your-listing-analytics-row-inner {
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
}

.pdf-your-listing-analytics-name {
  font-size: 58px;
  line-height: 74px;
  font-weight: 600;
  color: #ffffff;
  text-transform: capitalize;
  word-break: break-word;
  margin-bottom: 17px;
}

.pdf-your-listing-analytics-address {
  font-size: 32px;
  line-height: 41px;
  color: rgba(#ffffff, 0.8);
  word-break: break-word;
  font-style: normal;
}

// pdf your listing analytics row END

// pdf total listing Head START
.pdf-total-listing-head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 44px 0 36px 0;
  white-space: nowrap;

  > h3 {
    font-size: 40px;
    line-height: 51px;
  }
}

.pdf-date {
  display: flex;
  align-items: center;

  > i {
    flex: 0 0 auto;
    display: block;
    font-size: 24px;
    width: 24px;
    height: 24px;
    //background-image: url("../images/calendar.svg");
    background-repeat: no-repeat;
    background-size: contain;
    margin-right: 12px;
  }

  > p {
    font-size: 24px;
    line-height: 30px;
  }
}

// pdf total listing Head END
// pdf Cards START
.pdf-card-row {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 40px;
  margin-bottom: 40px;
  width: 100%;
}


//
//.pdf-card {
//  width: 100%;
//  border: 1px solid #efefef;
//  box-shadow: 0 2px 8px #f0f0f0;
//  background: #ffffff;
//}
//
//.pdf-card-head {
//  display: flex;
//  flex-direction: column;
//  justify-content: center;
//  height: 174px;
//  padding: 10px 40px;
//  border-bottom: 1px solid #efefef;
//  width: 100%;
//  > h3 {
//    font-size: 36px;
//    line-height: 46px;
//  }
//  > p {
//    font-size: 22px;
//    line-height: 36px;
//    color: rgba(#100B28, 0.64);
//    font-weight: 300;
//  }
//}
//
//.pdf-card-body {
//  display: flex;
//  align-items: center;
//  width: 100%;
//}
//
//.pdf-pie-chart-cnt {
//  display: grid;
//  align-items: center;
//  grid-template-columns: 1fr 1fr;
//  width: 100%;
//  padding: 70px 58px;
//}
//
//.pdf-pie-chart {
//  display: flex;
//  align-items: center;
//  width: 100%;
//  height: 100%;
//}
//
//.pdf-pie-chart-inner {
//  display: flex;
//  align-items: center;
//  justify-content: center;
//  position: relative;
//}
//
//.pdf-pie-chart-img {
//  display: block;
//  flex: 0 0 auto;
//  object-fit: contain;
//  width: 304px;
//  height: 304px;
//}
//.pdf-pie-chart-info{
//  display: flex;
//  align-items: center;
//  justify-content: center;
//  flex-direction: column;
//  position: absolute;
//  width: 100%;
//  height: 100%;
//  left: 0;
//  top: 0;
//  text-align: center;
//  z-index: 10;
//  > h3 {
//    font-size: 54px;
//    line-height: 68px;
//  }
//  > p {
//    font-size: 20px;
//    line-height: 25px;
//    text-transform: capitalize;
//    color: rgba(#100B28, 0.72);
//  }
//}
//.pdf-pie-list{
//  width: 100%;
//}
//
//.pdf-list-row{
//  display: flex;
//  align-items: center;
//  width: 100%;
//  &:not(:last-child){
//    margin-bottom: 26px;
//  }
//}
//
//
//.pdf-list-item-dot{
//  flex: 0 0 auto;
//  width: 14px;
//  height: 14px;
//  border-radius: 50%;
//  margin-right: 15px;
//}
//
//
//@for $i from 1 through 14 {
//  .pdf-list-row:nth-child(#{$i}) {
//    .pdf-list-item-dot{
//      background-color: var(--chart-exposure#{$i});
//    }
//  }
//}
//
//.pdf-list-item-info{
//  flex: 1;
//  display: flex;
//  justify-content: space-between;
//  align-items: center;
//  flex-wrap: wrap;
//  font-size: 22px;
//  line-height: 28px;
//}
//// pdf Cards END
