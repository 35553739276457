.client-finder-notification {
  position: relative;
  display: flex;
  align-items: center;
  padding: 0.8rem 1.2rem;
  margin-top: 1.6rem;
  width: 100%;
  min-height: 36px;
  background: #ffffff;
  box-shadow: 0 10px 25px #0000000f;
  border: 1px solid #ffffff;
  border-radius: 1px;
  opacity: 1;
  font-size: 1.6rem;
  line-height: 2rem;
  color: rgba(var(--background), 1);

  &.warning{
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0 8px 16px rgba(var(--danger-color), 0.12);
    font-weight: 510;

    &:before{
      width: 0.4rem;
    }

    .icon-info-full{
      color: rgba(var(--danger-color), 1);;
    }
  }

  &:before {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    bottom: 0;
    width: 0;
    height: 100%;
    background-color: rgba(var(--danger-color), 1);;
    transition: width 120ms;
  }

  > i {
    font-size: 1.6rem;
    margin-right: 0.8rem;
    color: #A2A0AB;
  }
}
