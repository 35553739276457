// To DO need delete all google  ad code 
.google-preview {
  display: flex;
  flex-direction: column;
  padding: 10px;
  background-color: rgba(var(--background), 1);
  white-space: initial;
  margin-bottom: auto;
  cursor: pointer;
  max-width: 600px;
  &.vertical {
    width: 286px;
    height: 572px;
    .google-ad-img {
      height: 369px;
    }
    .google-ad-title {
      font-size: 23px;
      line-height: 28px;
      margin-bottom: 16px;
      letter-spacing: -0.08px;
    }
    .google-ad-user-info-logo {
      width: 190px;
    }
    .google-ad-user-info {
      font-size: 7px;
      line-height: 9px;
      letter-spacing: 0.1px;
    }
    .google-ad-user-name {
      font-size: 12px;
      line-height: 15px;
    }
    .google-ad-user-img {
      width: 34px;
      height: 34px;
      margin-right: 6px;
    }
    .google-ad-button {
      height: 30px;
      padding: 0 10px;
      width: 95px;
      font-size: 9px;
      letter-spacing: 1.57px;
    }
    .google-ad-company-logo {
      padding-right: 13px;
    }
  }
  &.square {
    width: 380px;
    height: 380px;
    .google-ad-img {
      height: 242px;
    }
    .google-ad-user-and-title {
      display: flex;
      gap: 16px;
    }

    .google-ad-title {
      flex: 1;
      font-size: 17px;
      line-height: 21px;
    }

    .google-ad-user-info-logo {
      width: 150px;
    }

    .google-ad-user-info-column {
      padding-right: 5px;
    }

    .google-ad-user {
      flex: 0 0 auto;
      max-width: 45%;
    }

    .google-ad-user-info {
      font-size: 9px;
      line-height: 11px;
      letter-spacing: 0.14px;
    }

    .google-ad-user-img {
      width: 36px;
      height: 36px;
      margin-right: 8px;
    }

    .google-ad-user-name {
      font-size: 15px;
      line-height: 19px;
    }

    .google-ad-button {
      height: 36px;
      padding: 0 10px;
      width: 150px;
      font-size: 12px;
      letter-spacing: 2.1px;
    }

    .google-ad-company-logo {
      padding-right: 16px;
    }
  }
}
.google-ad-img {
  flex: 0 0 auto;
  display: block;
  width: 100%;
  object-fit: cover;
  object-position: center;
}
.google-ad-bottom {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  padding: 9px 12px 14px 12px;
  background-color: #ffffff;
}
.google-ad-user-and-title {
  width: 100%;
  padding-bottom: 6px;
}
.google-ad-title {
  color: rgba(var(--background), 1);
  word-break: break-word;
  font-weight: 600;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.google-ad-user {
  width: 100%;
  min-height: fit-content;
}
.google-ad-user-logo-and-text {
  display: flex;
  align-items: center;
}
.google-ad-user-img {
  flex: 0 0 auto;
  display: block;
  border-radius: 50%;
  object-fit: cover;
  max-width: 50px;
  max-height: 50px;
  > div {
    width: 100% !important;
    height: 100% !important;
    margin: 0 !important;
  }
}
.google-ad-user-info-column {
  flex: 1;
  color: rgba(var(--background), 1);
  min-width: 0;
}
.google-ad-user-info {
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.google-ad-user-name {
  font-weight: bold;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.google-ad-company-info {
  display: flex;
  align-items: center;
  width: 100%;
}
.google-ad-company-logo {
  flex: 1;
}
.google-ad-company-img {
  display: block;
  width: 100%;
  object-fit: contain;
}
.google-ad-button {
  flex: 0 0 auto;
  outline: none;
  border: none;
  background-color: rgba(var(--background), 1);
  text-transform: uppercase;
  color: #ffffff;
  cursor: pointer;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  font-weight: 600;

  &:active {
    transform: scale(0.98);
  }
}
.google-ad-user-info-logo {
  width: 190px;
  display: block;
  object-fit: contain;
}
// in popup STYLES START
.preview-your-ad-popup {
  .google-preview {
    cursor: default;
    &.vertical {
      width: 318px;
      height: 636px;
      .google-ad-img {
        height: 410px;
      }
      .google-ad-bottom{
        padding: 10px 13px 16px 13px;
      }
      .google-ad-title{
        font-size: 24px;
        line-height: 30px;
        margin-bottom: 22px;
      }
      .google-ad-user-img{
        width: 38px;
        height: 38px;
        margin-right: 7px;
      }
      .google-ad-user-info{
        font-size: 9px;
        line-height: 11px;
      }
      .google-ad-user-name{
        font-size: 14px;
        line-height: 18px;
      }
      .google-ad-company-logo{
        padding-right: 12px;
      }
      .google-ad-button{
        width: 110px;
        height: 33px;
        font-size: 10px;
        letter-spacing: 1.75px;
      }
    }
    &.square{
      width: 420px;
      height: 420px;
      .google-ad-img {
        height: 268px;
      }
      .google-ad-bottom{
        padding: 10px 13px 16px 13px;
      }
      .google-ad-title{
        font-size: 18px;
        line-height: 24px;
      }
      .google-ad-user-img{
        width: 40px;
        height: 40px;
        margin-right: 9px;
      }
      .google-ad-user-info{
        font-size: 10px;
        line-height: 13px;
      }
      .google-ad-user-name{
        font-size: 17px;
        line-height: 22px;
      }
      .google-ad-company-logo{
        padding-right: 18px;
      }
      .google-ad-button{
        width: 166px;
        height: 40px;
        font-size: 14px;
        letter-spacing: 2.45px;
      }
    }
  }
}
// in popup STYLES END
@media screen and (max-width: 1610px) and (min-width: 980px) {
  .google-preview {
    &.vertical {
      padding: 7px;
      width: 222px;
      height: 444px;
      .google-ad-bottom {
        padding: 10px 9px 9px 9px;
      }
      .google-ad-img {
        height: 286px;
      }

      .google-ad-user-and-title {
        padding-bottom: 22px;
      }

      .google-ad-title {
        font-size: 17px;
        line-height: 21px;
        margin-bottom: 12px;
        letter-spacing: 0;
      }

      .google-ad-user-info-logo {
        width: 148px;
      }

      .google-ad-user-info {
        font-size: 6px;
        line-height: 8px;
        letter-spacing: 0.09px;
      }

      .google-ad-user-name {
        font-size: 10px;
        line-height: 13px;
      }

      .google-ad-user-img {
        width: 26px;
        height: 26px;
        margin-right: 6px;
      }

      .google-ad-button {
        height: 23px;
        padding: 0 6px;
        width: 74px;
        font-size: 8px;
        letter-spacing: 1px;
      }

      .google-ad-company-logo {
        padding-right: 9.44px;
      }
    }
    &.square {
      width: 300px;
      height: 300px;
      padding: 8px;
      .google-ad-img {
        height: 190px;
      }

      .google-ad-bottom {
        padding: 6px 10px 12px 10px;
      }

      .google-ad-user-and-title {
        gap: 12px;
        padding-bottom: 13px;
      }

      .google-ad-title {
        font-size: 13px;
        line-height: 17px;
      }

      .google-ad-user-info-logo {
        width: 118px;
      }

      .google-ad-user-info-column {
        padding-right: 5px;
      }

      .google-ad-user-info {
        font-size: 7px;
        line-height: 10px;
        letter-spacing: 0.17px;
      }

      .google-ad-user-img {
        width: 27px;
        height: 27px;
        margin-right: 7px;
      }

      .google-ad-user-name {
        font-size: 12px;
        line-height: 15px;
      }

      .google-ad-button {
        height: 29px;
        padding: 0 7px;
        width: 118px;
        font-size: 10px;
        letter-spacing: 1.5px;
      }

      .google-ad-company-logo {
        padding-right: 12px;
      }
    }
  }
  // in popup STYLES START
  .preview-your-ad-popup {
    .google-preview {
      &.vertical {
        width: 245px;
        height: 490px;
        .google-ad-img {
          height: 315px;
        }
        .google-ad-bottom{
          padding: 11px 10px 12px 12px;
        }
        .google-ad-title{
          font-size: 19px;
          line-height: 22px;
          margin-bottom: 14px;
        }
        .google-ad-user-img{
          width: 30px;
          height: 30px;
          margin-right: 5px;
        }
        .google-ad-user-info{
          font-size: 7px;
          line-height: 9px;
        }
        .google-ad-user-name{
          font-size: 11px;
          line-height: 14px;
        }
        .google-ad-company-logo{
          padding-right: 12px;
        }
        .google-ad-button{
          width: 81px;
          height: 26px;
          font-size: 8px;
          letter-spacing: 1px;
        }
      }
      &.square{
        width: 350px;
        height: 350px;
        .google-ad-img {
          height: 223px;
        }
        .google-ad-bottom{
          padding: 9px 12px 14px 12px;
        }
        .google-ad-title{
          font-size: 15px;
          line-height: 20px;
        }
        .google-ad-user-img{
          width: 32px;
          height: 32px;
          margin-right: 8px;
        }
        .google-ad-user-info{
          font-size: 9px;
          line-height: 12px;
        }
        .google-ad-user-name{
          font-size: 14px;
          line-height: 18px;
        }
        .google-ad-company-logo{
          padding-right: 14px;
        }
        .google-ad-button{
          width: 138px;
          height: 34px;
          font-size: 12px;
          letter-spacing: 1.44px;
        }
      }
    }
  }
  // in popup STYLES END
}
@media screen and (max-width: 738px) {
  .google-preview {
    margin: auto 0;
    &.vertical {
      width: 180px;
      height: 360px;
      padding: 6px;

      .google-ad-img {
        height: 232px;
      }

      .google-ad-bottom {
        padding: 8px 7px 7px 7px;
      }

      .google-ad-user-and-title {
        padding-bottom: 17px;
      }

      .google-ad-title {
        font-size: 14px;
        line-height: 17.5px;
        margin-bottom: 9px;
        letter-spacing: 0;
      }

      .google-ad-user-info-logo {
        width: 120px;
      }

      .google-ad-user-info {
        font-size: 5px;
        line-height: 7px;
        letter-spacing: 0.07px;
      }

      .google-ad-user-name {
        font-size: 8px;
        line-height: 10px;
      }

      .google-ad-user-img {
        width: 22px;
        height: 22px;
        margin-right: 3px;
      }

      .google-ad-button {
        height: 20px;
        padding: 0 5px;
        width: 60px;
        font-size: 6px;
        letter-spacing: 1.05px;
      }

      .google-ad-company-logo {
        padding-right: 8px;
      }
    }
    &.square {
      padding: 6px;
      width: 240px;
      height: 240px;

      .google-ad-img {
        height: 153px;
      }
      .google-ad-bottom{
        padding: 5px 8px 10px 8px;
      }

      .google-ad-user-and-title {
        gap: 8px;
      }

      .google-ad-title {
        font-size: 11px;
        line-height: 14px;
      }

      .google-ad-user-info-logo {
        width: 94px;
      }

      .google-ad-user-info {
        font-size: 6px;
        line-height: 8px;
        letter-spacing: 0.09px;
      }

      .google-ad-user-img {
        width: 21px;
        height: 21px;
        margin-right: 5px;
      }

      .google-ad-user-name {
        font-size: 9px;
        line-height: 11px;
      }

      .google-ad-button {
        height: 23px;
        padding: 0 5px;
        width: 94px;
        font-size: 8px;
        letter-spacing: 1.4px;
      }

      .google-ad-company-logo {
        padding-right: 11px;
      }
    }
  }
}
