.google-ad-pdf-container{
  display: inline-block;
  vertical-align: top;
  position: fixed;
  top: 10000px;
  left: 0;
  width: auto;
  height: auto;
  &.for-test{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    top: 0;
    background-color: #FCFCFC;
    z-index: 500;
  }
}
.google-ad-pdf{
  display: flex;
  justify-content: space-between;
  background-color: #100b28;
  box-shadow: 0 10px 25px #E6E6E6;
  &.skyscraper{
    width: 120px;
    height: 600px;
    padding: 6px;
    flex-direction: column;
    .google-ad-img-pdf{
      height: 246px;
    }
    .google-ad-bottom-pdf{
      flex-direction: column;
      padding: 14px 6px 6px 6px;
    }
    .google-ad-title-pdf{
      font-size: 16px;
      line-height: 20px;
      text-align: center;
      margin-bottom: 34px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 4;
      -webkit-box-orient: vertical;
    }
    .google-ad-user-img-pdf{
      width: 40px;
      height: 40px;
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      top: -5px;
      margin: auto;
    }
    .google-ad-user-and-title-pdf{
      flex: 1;
      flex-direction: column;
      justify-content: space-between;
      padding-bottom: 35px;
    }
    .google-ad-user-info-pdf{
      flex: 1;
      flex-direction: column;
      align-items: center;
      text-align: center;
      position: relative;
    }
    .google-ad-user-info-column-pdf{
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
    }
    .google-ad-user-info-text-pdf{
      font-size: 9px;
      line-height: 12px;
      letter-spacing: 0.14px;
      padding: 0 10px;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
    .google-ad-user-name-pdf{
      font-weight: bold;
      font-size: 13px;
      line-height: 16px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      padding: 0 10px;
    }
    .google-ad-company-info-pdf{
      flex-direction: column;
    }
    .google-ad-company-info-pdf-img-column{
      width: 100%;
      margin-bottom: 16px;
    }
    .google-ad-button-pdf{
      height: 32px;
      width: 100%;
      font-size: 10px;
      letter-spacing: 1.75px;
    }
  }
  &.wide-skyscraper{
    width: 160px;
    height: 600px;
    padding: 7px;
    flex-direction: column;
    .google-ad-img-pdf{
      height: 247px;
    }
    .google-ad-bottom-pdf {
      flex-direction: column;
      padding: 12px 7px 7px 7px;
    }
    .google-ad-title-pdf{
      font-size: 16px;
      line-height: 20px;
      text-align: center;
      margin-bottom: 20px;
      overflow: hidden;
      text-overflow: ellipsis;
      padding: 0 16px;
      display: -webkit-box;
      -webkit-line-clamp: 4;
      -webkit-box-orient: vertical;
    }
    .google-ad-user-img-pdf{
      width: 40px;
      height: 40px;
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      top: -7px;
      margin: auto;
    }
    .google-ad-user-and-title-pdf{
      flex: 1;
      flex-direction: column;
      justify-content: space-between;
      padding-bottom: 23px;
    }
    .google-ad-user-info-pdf{
      flex: 1;
      flex-direction: column;
      align-items: center;
      text-align: center;
      position: relative;
    }
    .google-ad-user-info-column-pdf{
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
    }
    .google-ad-user-info-text-pdf{
      font-size: 9px;
      line-height: 12px;
      letter-spacing: 0.14px;
      padding: 0 28px;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
    .google-ad-user-name-pdf{
      font-size: 14px;
      line-height: 18px;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      padding: 0 20px;
    }
    .google-ad-company-info-pdf{
      flex-direction: column;
    }
    .google-ad-company-info-pdf-img-column{
      width: 100%;
      margin-bottom: 16px;
    }
    .google-ad-button-pdf{
      height: 32px;
      width: 100%;
      font-size: 10px;
      letter-spacing: 1.75px;
    }
  }
  &.small-square{
    width: 200px;
    height: 200px;
    padding: 5px;
    flex-direction: column;
    .google-ad-img-pdf{
      height: 125px;
    }
    .google-ad-bottom-pdf {
      flex-direction: column;
      padding: 6px 6px 7px 6px;
    }
    .google-ad-user-and-title-pdf{
      align-items: center;
      gap: 10px;
      padding-bottom: 6px;
    }
    .google-ad-title-pdf{
      flex: 1;
      font-size: 9px;
      line-height: 12px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
    .google-ad-user-img-pdf{
      width: 20px;
      height: 20px;
      margin-right: 4px;
    }
    .google-ad-user-info-pdf{
      flex: 0 0 auto;
      align-items: center;
      width: 78px;
    }
    .google-ad-user-info-column-pdf{
      flex: 1;
      flex-direction: column;
      justify-content: center;
    }
    .google-ad-user-info-text-pdf{
      font-size: 5px;
      line-height: 6px;
      letter-spacing: 0.07px;
      white-space: nowrap;
    }
    .google-ad-user-name-pdf{
      font-size: 8px;
      white-space: nowrap;
    }
    .google-ad-company-info-pdf{
      gap: 10px;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
    }
    .google-ad-company-logo-pdf{
      width: 78px;
    }
    .google-ad-button-pdf{
      flex: 0 0 auto;
      height: 19px;
      width: 78px;
      font-size: 7px;
      letter-spacing: 1.23px;
    }
  }
  &.square{
    width: 250px;
    height: 250px;
    padding: 7px;
    flex-direction: column;
    .google-ad-img-pdf{
      height: 159px;
    }
    .google-ad-bottom-pdf {
      flex-direction: column;
      padding: 5px 8px 10px 8px;
    }
    .google-ad-user-and-title-pdf{
      align-items: center;
      gap: 10px;
      padding-bottom: 6px;
    }
    .google-ad-title-pdf{
      flex: 1;
      font-size: 11px;
      line-height: 14px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
    .google-ad-user-img-pdf{
      width: 22px;
      height: 22px;
      margin-right: 6px;
    }
    .google-ad-user-info-pdf{
      flex: 0 0 auto;
      align-items: center;
      width: 98px;
    }
    .google-ad-user-info-column-pdf{
      flex: 1;
      flex-direction: column;
      justify-content: center;
    }
    .google-ad-user-info-text-pdf{
      font-size: 6px;
      line-height: 8px;
      letter-spacing: 0.09px;
      white-space: nowrap;
    }
    .google-ad-user-name-pdf{
      font-size: 10px;
      white-space: nowrap;
    }
    .google-ad-company-info-pdf{
      gap: 10px;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
    }
    .google-ad-company-logo-pdf{
      width: 98px;
    }
    .google-ad-button-pdf{
      flex: 0 0 auto;
      height: 19px;
      width: 98px;
      font-size: 7px;
      letter-spacing: 1.23px;
    }
  }
  &.embedded-rectangle{
    width: 300px;
    height: 250px;
    padding: 7px;
    flex-direction: column;
    .google-ad-img-pdf{
      height: 150px;
    }
    .google-ad-bottom-pdf {
      flex-direction: column;
      padding: 8px 6px;
    }
    .google-ad-user-and-title-pdf{
      align-items: center;
      gap: 14px;
      padding-bottom: 6px;
    }
    .google-ad-title-pdf{
      flex: 1;
      font-size: 13px;
      line-height: 16px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
    .google-ad-user-img-pdf{
      width: 28px;
      height: 28px;
      margin-right: 6px;
    }
    .google-ad-user-info-pdf{
      flex: 0 0 auto;
      align-items: center;
      width: 116px;
    }
    .google-ad-user-info-column-pdf{
      flex: 1;
      flex-direction: column;
      justify-content: center;
    }
    .google-ad-user-info-text-pdf{
      font-size: 6px;
      line-height: 8px;
      letter-spacing: 0.09px;
      white-space: nowrap;
    }
    .google-ad-user-name-pdf{
      font-size: 10px;
      white-space: nowrap;
    }
    .google-ad-company-info-pdf{
      gap: 14px;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
    }
    .google-ad-company-logo-pdf{
      width: 116px;
    }
    .google-ad-button-pdf{
      flex: 0 0 auto;
      height: 26px;
      width: 116px;
      font-size: 8px;
      letter-spacing: 1.4px;
    }
  }
  &.big-rectangle{
    width: 336px;
    height: 280px;
    padding: 8px;
    flex-direction: column;
    .google-ad-img-pdf{
      height: 176px;
    }
    .google-ad-bottom-pdf {
      flex-direction: column;
      padding: 6px 10px 10px 10px;
    }
    .google-ad-user-and-title-pdf{
      align-items: center;
      gap: 16px;
      padding-bottom: 6px;
    }
    .google-ad-title-pdf{
      flex: 1;
      font-size: 14px;
      line-height: 17px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
    .google-ad-user-img-pdf{
      width: 30px;
      height: 30px;
      margin-right: 6px;
    }
    .google-ad-user-info-pdf{
      flex: 0 0 auto;
      align-items: center;
      width: 100%;
    }
    .google-ad-user-info-column-pdf{
      flex: 1;
      flex-direction: column;
      justify-content: center;
    }
    .google-ad-user-info-text-pdf{
      font-size: 7px;
      line-height: 9px;
      letter-spacing: 0.1px;
      white-space: nowrap;
    }
    .google-ad-user-name-pdf{
      font-size: 11px;
      white-space: nowrap;
    }
    .google-ad-company-info-pdf{
      gap: 16px;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
    }
    .google-ad-company-logo-pdf{
      width: 132px;
    }
    .google-ad-button-pdf{
      flex: 0 0 auto;
      height: 29px;
      width: 132px;
      font-size: 10px;
      letter-spacing: 1.75px;
    }
  }
  &.vertical-rectangle{
    width: 240px;
    height: 400px;
    padding: 8px;
    flex-direction: column;
    .google-ad-img-pdf{
      height: 236px;
    }
    .google-ad-bottom-pdf {
      flex-direction: column;
      padding: 8px 10px 10px 10px;
    }
    .google-ad-user-and-title-pdf{
      flex-direction: column;
      padding-bottom: 6px;
    }
    .google-ad-title-pdf{
      flex: 1;
      font-size: 18px;
      line-height: 21px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      margin-bottom: 12px;
    }
    .google-ad-user-img-pdf{
      width: 30px;
      height: 30px;
      margin-right: 6px;
    }
    .google-ad-user-info-pdf{
      flex: 0 0 auto;
      align-items: center;
      width: 100%;
    }
    .google-ad-user-info-column-pdf{
      flex: 1;
      flex-direction: column;
      justify-content: center;
    }
    .google-ad-user-info-text-pdf{
      font-size: 7px;
      line-height: 8px;
      letter-spacing: 0.09px;
      white-space: nowrap;
    }
    .google-ad-user-name-pdf{
      font-size: 10px;
      white-space: nowrap;
    }
    .google-ad-company-info-pdf{
      gap: 8px;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
    }
    .google-ad-company-logo-pdf{
      width: 164px;
    }
    .google-ad-button-pdf{
      flex: 0 0 auto;
      height: 26px;
      width: 84px;
      font-size: 8px;
      letter-spacing: 1.4px;
    }
  }
  &.half-page-block{
    width: 300px;
    height: 600px;
    padding: 10px;
    flex-direction: column;
    .google-ad-img-pdf{
      height: 386px;
    }
    .google-ad-bottom-pdf {
      flex-direction: column;
      padding: 13px 12px 14px 12px;
    }
    .google-ad-user-and-title-pdf{
      flex-direction: column;
      padding-bottom: 6px;
    }
    .google-ad-title-pdf{
      flex: 1;
      font-size: 23px;
      line-height: 28px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      margin-bottom: 16px;
    }
    .google-ad-user-img-pdf{
      width: 36px;
      height: 36px;
      margin-right: 6px;
    }
    .google-ad-user-info-pdf{
      flex: 0 0 auto;
      align-items: center;
      width: 100%;
    }
    .google-ad-user-info-column-pdf{
      flex: 1;
      flex-direction: column;
      justify-content: center;
    }
    .google-ad-user-info-text-pdf{
      font-size: 8px;
      line-height: 10px;
      letter-spacing: 0.12px;
      white-space: nowrap;
    }
    .google-ad-user-name-pdf{
      font-size: 13px;
      white-space: nowrap;
    }
    .google-ad-company-info-pdf{
      gap: 12px;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
    }
    .google-ad-company-logo-pdf{
      width: 200px;
    }
    .google-ad-button-pdf{
      flex: 0 0 auto;
      height: 32px;
      width: 100px;
      font-size: 10px;
      letter-spacing: 1.75px;
    }
  }
  &.full-size-mobile-banner{
    width: 320px;
    height: 50px;
    padding: 3px;
    .google-ad-img-pdf{
      height: 100%;
      width: 113px;
    }
    .google-ad-bottom-pdf {
      flex-direction: column;
      padding: 4px 5px 4px 8px;
    }
    .google-ad-user-and-title-pdf{
      align-items: center;
      gap: 12px;
      padding-bottom: 3px;
    }
    .google-ad-title-pdf{
      flex: 1;
      font-size: 8px;
      line-height: 9px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
    .google-ad-user-img-pdf{
      width: 16px;
      height: 16px;
      margin-right: 3px;
      display: none;
    }
    .google-ad-user-info-pdf{
      flex: 0 0 auto;
      align-items: center;
      width: 80px;
    }
    .google-ad-user-info-column-pdf{
      flex: 1;
      flex-direction: column;
      justify-content: center;
    }
    .google-ad-user-info-text-pdf{
      font-size: 5px;
      line-height: 6px;
      letter-spacing: 0.07px;
      white-space: nowrap;
    }
    .google-ad-user-name-pdf{
      font-size: 8px;
      line-height: 10px;
      white-space: nowrap;
    }
    .google-ad-company-info-pdf{
      gap: 10px;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
    }
    .google-ad-company-img-pdf{
      max-width: 80px;
    }
    .google-ad-company-logo-pdf{
      width: 80px;
    }
    .google-ad-button-pdf{
      flex: 0 0 auto;
      height: 17px;
      width: 80px;
      font-size: 7px;
      letter-spacing: 1.23px;
    }
  }
  &.large-mobile{
    width: 320px;
    height: 100px;
    padding: 4px;
    .google-ad-img-pdf{
      height: 100%;
      width: 126px;
    }
    .google-ad-bottom-pdf {
      flex-direction: column;
      padding: 5px 8px 7px 8px;
    }
    .google-ad-user-and-title-pdf{
      flex-direction: column;
      padding-bottom: 4px;
    }
    .google-ad-title-pdf{
      flex: 1;
      font-size: 13px;
      line-height: 15px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      margin-bottom: 6px;
    }
    .google-ad-user-img-pdf{
      width: 16px;
      height: 16px;
      margin-right: 4px;
      //
      display: none;
      //
    }
    .google-ad-user-info-pdf{
      flex: 0 0 auto;
      align-items: center;
      width: 100%;
    }
    .google-ad-user-info-column-pdf{
      flex: 1;
      flex-direction: column;
      justify-content: center;
    }
    .google-ad-user-info-text-pdf{
      font-size: 5px;
      line-height: 6px;
      letter-spacing: 0.07px;
      white-space: nowrap;
    }
    .google-ad-user-name-pdf{
      font-size: 8px;
      line-height: 10px;
      white-space: nowrap;
    }
    .google-ad-company-info-pdf{
      gap: 9px;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
    }
    .google-ad-company-logo-pdf{
      width: 86px;
    }
    .google-ad-button-pdf{
      flex: 0 0 auto;
      height: 21px;
      width: 78px;
      font-size: 7px;
      letter-spacing: 1.23px;
    }
  }
  &.banner{
    width: 468px;
    height: 60px;
    padding: 4px;
    .google-ad-img-pdf{
      height: 100%;
      width: 117px;
    }
    .google-ad-bottom-pdf {
      flex-direction: row;
      padding: 8px 6px 6px 8px;
      gap: 13px;
    }
    .google-ad-user-and-title-pdf{
      flex: 1;
      align-items: center;
      justify-content: space-between;
      gap: 6px;
    }
    .google-ad-title-pdf{
      flex: 1;
      font-size: 13px;
      line-height: 16px;
      max-width: 140px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
    .google-ad-user-img-pdf{
      width: 16px;
      height: 16px;
      margin-right: 3px;
      display: none;
    }
    .google-ad-user-info-pdf{
      flex: 0 0 auto;
      align-items: center;
      width: 72px;
    }
    .google-ad-company-logo-pdf{
      width: 72px;
    }
    .google-ad-user-info-column-pdf{
      flex: 1;
      flex-direction: column;
      justify-content: center;
    }
    .google-ad-user-info-text-pdf{
      font-size: 6px;
      line-height: 8px;
      letter-spacing: 0.09px;
      white-space: nowrap;
    }
    .google-ad-user-name-pdf{
      font-size: 9px;
      line-height: 11px;
      white-space: nowrap;
    }
    .google-ad-company-info-pdf{
      flex: 0 0 auto;
      width: 88px;
      gap: 4px;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
    }
    .google-ad-button-pdf{
      height: 18px;
      width: 100%;
      font-size: 7px;
      letter-spacing: 1.23px;
    }
  }
  &.full-size-ad{
    width: 728px;
    height: 90px;
    padding: 5px;
    .google-ad-img-pdf{
      height: 100%;
      width: 197px;
    }
    .google-ad-bottom-pdf {
      flex-direction: row;
      padding: 11px 11px 10px 22px;
      gap: 28px;
    }
    .google-ad-user-and-title-pdf{
      flex: 1;
      align-items: center;
      justify-content: space-between;
      gap: 6px;
    }
    .google-ad-title-pdf{
      flex: 1;
      font-size: 19px;
      line-height: 23px;
      max-width: 212px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
    .google-ad-user-img-pdf{
      width: 25px;
      height: 25px;
      margin-right: 6px;
      display: none;
    }
    .google-ad-user-info-pdf{
      flex: 0 0 auto;
      align-items: center;
      width: 100px;
    }
    .google-ad-company-logo-pdf{
      width: 100px;
    }
    .google-ad-user-info-column-pdf{
      flex: 1;
      flex-direction: column;
      justify-content: center;
    }
    .google-ad-user-info-text-pdf{
      font-size: 9px;
      line-height: 11px;
      letter-spacing: 0.14px;
      white-space: nowrap;
    }
    .google-ad-user-name-pdf{
      font-size: 13px;
      line-height: 15px;
      white-space: nowrap;
    }
    .google-ad-company-info-pdf{
      flex: 0 0 auto;
      width: 132px;
      gap: 4px;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
    }
    .google-ad-button-pdf{
      flex: 0 0 auto;
      height: 29px;
      width: 100%;
      font-size: 10px;
      letter-spacing: 1.75px;
    }
  }
  &.large-full-size-block{
    width: 970px;
    height: 90px;
    padding: 5px;
    .google-ad-img-pdf{
      height: 100%;
      width: 255px;
    }
    .google-ad-bottom-pdf {
      flex-direction: row;
      padding: 11px 19px 10px 24px;
      gap: 48px;
    }
    .google-ad-user-and-title-pdf{
      flex: 1;
      align-items: center;
      justify-content: space-between;
      gap: 6px;
    }
    .google-ad-title-pdf{
      flex: 1;
      font-size: 22px;
      line-height: 25px;
      max-width: 256px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
    .google-ad-user-img-pdf{
      width: 40px;
      height: 40px;
      margin-right: 7px;
    }
    .google-ad-user-info-pdf{
      flex: 0 0 auto;
      align-items: center;
      width: 162px;
    }
    .google-ad-company-logo-pdf{
      width: 162px;
    }
    .google-ad-user-info-column-pdf{
      flex: 1;
      flex-direction: column;
      justify-content: center;
    }
    .google-ad-user-info-text-pdf{
      font-size: 9px;
      line-height: 11px;
      letter-spacing: 0.14px;
      white-space: nowrap;
    }
    .google-ad-user-name-pdf{
      font-size: 14px;
      line-height: 18px;
      white-space: nowrap;
    }
    .google-ad-company-info-pdf{
      flex: 0 0 auto;
      width: 156px;
      gap: 4px;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
    }
    .google-ad-button-pdf{
      flex: 0 0 auto;
      height: 29px;
      width: 100%;
      font-size: 10px;
      letter-spacing: 1.75px;
    }
  }
  &.billboard{
    width: 970px;
    height: 250px;
    padding: 12px;
    .google-ad-img-pdf{
      height: 100%;
      width: 536px;
    }
    .google-ad-bottom-pdf {
      flex-direction: column;
      padding: 20px 16px 16px 25px;
    }
    .google-ad-user-and-title-pdf{
      flex-direction: column;
      padding-bottom: 4px;
    }
    .google-ad-title-pdf{
      flex: 1;
      font-size: 32px;
      line-height: 37px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      margin-bottom: 16px;
    }
    .google-ad-user-img-pdf{
      width: 40px;
      height: 40px;
      margin-right: 8px;
    }
    .google-ad-user-info-pdf{
      flex: 0 0 auto;
      align-items: center;
      width: 100%;
    }
    .google-ad-user-info-column-pdf{
      flex: 1;
      flex-direction: column;
      justify-content: center;
    }
    .google-ad-user-info-text-pdf{
      font-size: 9px;
      line-height: 11px;
      letter-spacing: 0.14px;
      white-space: nowrap;
    }
    .google-ad-user-name-pdf{
      font-size: 14px;
      line-height: 18px;
      white-space: nowrap;
    }
    .google-ad-company-info-pdf{
      gap: 20px;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
    }
    .google-ad-company-logo-pdf{
      width: 197px;
    }
    .google-ad-button-pdf{
      flex: 0 0 auto;
      height: 40px;
      width: 160px;
      font-size: 12px;
      letter-spacing: 2.1px;
    }
  }
}
.google-ad-img-pdf{
  flex: 0 0 auto;
  display: block;
  width: 100%;
  object-fit: cover;
}
.google-ad-bottom-pdf{
  flex: 1;
  display: flex;
  justify-content: space-between;
  background-color: #ffffff;
}
.google-ad-user-and-title-pdf{
  display: flex;
}
.google-ad-title-pdf{
  color: #100b28;
  font-weight: 600;
  word-break: break-word;
}
.google-ad-user-info-pdf{
  display: flex;
}
.google-ad-user-img-pdf{
  flex: 0 0 auto;
  display: block;
  max-width: 50px;
  border-radius: 50%;
  object-fit: cover;
}
.google-ad-user-info-column-pdf{
  display: flex;
  max-width: 100%;
  min-width: 0;
}
.google-ad-user-info-text-pdf{
  color: #100B28;
  text-overflow: ellipsis;
  overflow: hidden;
}
.google-ad-user-name-pdf{
  color: #100B28;
  font-weight: bold;
  overflow: hidden;
  text-overflow: ellipsis;
}
.google-ad-company-logo-pdf{
  display: block;
  flex: 0 0 auto;
  object-fit: contain;
}
.google-ad-company-info-pdf{
  display: flex;
}
.google-ad-company-info-pdf-img-column{
  flex: 1;
  min-width: 0;
}
.google-ad-company-img-pdf{
  display: block;
  object-fit: contain;
  width: 100%;
}
.google-ad-button-pdf{
  outline: none;
  border: none;
  padding: 0 4px;
  background-color: #100B28;
  color: #ffffff;
  text-transform: uppercase;
  font-weight: 600;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
