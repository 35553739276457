.analytics-dashboard-mobile {
  display: none;
}

.analytics-dashboard {
  display: grid;
  grid-template-rows: auto 11fr;
  width: 100%;
  height: 100%;

  .container {
    box-sizing: border-box;
  }

  .footer {
    padding: 5.6rem 0 3.6rem 0;
  }
}

.analytics-d-head {
  display: flex;
  align-items: center;
  height: 14rem;
  padding: 2rem 2.4rem;
  background-color: rgba(var(--background), 1);
}

.analytics-d-head-inner {
  display: flex;
  align-items: center;
  gap: 1rem;
  justify-content: space-between;
  box-sizing: content-box;
  padding: 0 2.4rem;
}

.analytics-d-head-column {
  flex: 0 0 auto;
  max-width: 100%;
}

.analytics-d-head-title {
  font-size: 4rem;
  line-height: 5.1rem;
  font-weight: 600;
  color: #ffffff;
  text-transform: capitalize;
  word-break: break-word;

  &:not(:only-child) {
    margin-bottom: 0.6rem;
  }
}

.analytics-d-content {
  display: grid;
  grid-template-rows: auto 11fr;
  width: 100%;
  height: 100%;
  padding: 4.1rem 2.4rem 0 2.4rem;
  box-sizing: content-box;
}

.analytics-d-tabs {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.analytics-d-table-holder {
  display: grid;
  grid-template-rows: auto 11fr;
  width: 100%;
  height: 100%;

  &:only-child {
    grid-row: span 2;
  }
}

.analytics-d-table-holder-head {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  background-color: #ffffff;
  padding: 4.1rem 3.9rem 2.9rem;
  box-shadow: 0 1rem 2.5rem #f0f0f0;
  border-bottom: 0.1rem solid rgba(var(--border-color), 1);
}

.analytics-d-table-holder-head-column {
  min-height: 4.4rem;
  min-width: 4.4rem;
  position: relative;

  &:first-child {
    flex: 1;
  }

  &:last-child {
    flex: 0 0 auto;
  }
}

.analytics-d-table-mobile-dropdown {
  width: 100%;
}

.analytics-d-table-title {
  font-size: 2.4rem;
  line-height: 3rem;
  margin-bottom: 2rem;
  font-weight: 600;
}

.analytics-d-table-holder-tabs {
  display: flex;
  gap: 1.3rem;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  position: relative;
}

.analytics-d-table-tab {
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  height: 3rem;
  gap: 0.5rem;
  padding: 0 1rem;
  cursor: pointer;

  > p {
    font-size: 1.4rem;
    line-height: 1.8rem;
    color: rgba(#100b28, 0.72);
    transition: color 240ms;
    margin-bottom: 0;
    font-weight: 510;
  }

  > span {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 0.4rem;
    border-radius: 6rem;
    color: rgba(var(--background), 1);
    background-color: #f2f2f4;
    font-size: 1.2rem;
    line-height: 1.8rem;
    height: 1.8rem;
    min-width: 1.8rem;
    transition: background-color 240ms;
    font-weight: 500;

    @media (max-width: 1919px) {
      font-size: 1.1rem;
    }
  }

  &.active {
    background-color: #323744;
    border-radius: 1px;
    cursor: default;

    > p {
      color: #ffffff;
    }
  }
}

.analytics-d-table-sort {
  position: absolute;
  right: 0;
  height: 4.4rem;
  width: 4.4rem;
  //top: 0;
  bottom: -2.5rem;
  //margin: auto 0;
  background-color: #ffffff;
  z-index: 2;
  border: 0.1rem solid transparent;
  transition: width 380ms ease-in-out, border 380ms, background-color 240ms;

  > input {
    outline: none;
    background: none;
    width: 100%;
    padding: 0 1rem 0 4rem;
    border: none;
    box-sizing: border-box;
    height: 100%;
    font-size: 1.5rem;
    color: rgba(var(--background), 1);
    transform: scale3d(0, 0, 0);
    transition: transform 240ms ease-in-out 100ms;

    &::placeholder {
      color: rgba(var(--background), 1);
    }
  }

  > i {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 4.4rem;
    font-size: 1.8rem;
    color: #534f64;
    cursor: pointer;
  }

  &.open {
    width: 28rem;
    border-color: #ecebee;
    background-color: #fafafa;

    > input {
      transform: scale3d(1, 1, 1);
    }

    > i {
      color: rgba(var(--brand-color), 1);
    }
  }
}

.analytics-d-table-holder-content {
  width: 100%;
  height: 100%;
  position: relative;
}

.analytics-d-table-holder-scroll {
  display: grid;
  grid-template-rows: 11fr auto;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  scrollbar-width: none;
  overflow-x: hidden;
  overflow-y: auto;

  &::-webkit-scrollbar {
    display: none;
  }
}

.analytics-d-table {
  display: grid;
  grid-template-columns: auto 11fr auto;
  width: 100%;
  height: 100%;
  box-shadow: 0 0.2rem 0.8rem #f0f0f0;
  background-color: #ffffff;
  position: relative;
  transition: padding 240ms;
}

.analytics-d-table-column {
  position: relative;

  &.left {
    width: 38.6rem;
    padding-left: 2.4rem;

    .analytics-d-table-row {
      padding-left: 1.5rem;
    }
  }

  &.center {
    -webkit-clip-path: (0 0, 100% 0, 100% 100%, 0% 100%);
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
  }

  &.right {
    width: 32rem;
    padding-right: 2.4rem;

    .analytics-d-table-row {
      padding-right: 1.6rem;
    }

    .analytics-d-table-row-col {
      &:first-child {
        flex: 0 0 auto;
        min-width: 7rem;
      }
    }
  }
}

.swipe-cnt {
  left: 0;
  top: 0;
  width: 100%;

  &.swipe {
    .analytics-d-table-row-col {
      transform: translate(-22rem, 0);
    }
  }
}

.analytics-d-table-row {
  display: flex;
  align-items: center;
  min-width: 100%;
  height: 8.4rem;
  border-bottom: 0.1rem solid rgba(var(--border-color), 1);
  background-color: #ffffff;
  position: relative;
  &.space-between {
    justify-content: space-between;
  }
  &.head {
    height: 6rem;
    top: 0;
    position: -webkit-sticky;
    position: sticky;
    z-index: 20;
    box-shadow: 0 -0.4rem 0 0 #ffffff;
  }

  &.total {
    height: 6.6rem;
  }

  //&:not(.head){
  //  cursor: pointer;
  //  &:before{
  //    content: '';
  //    display: block;
  //    position: absolute;
  //    left: 0;
  //    top: 0;
  //    width: 100vw;
  //    height: 100%;
  //    background-color: #FAFAFA;
  //    pointer-events: none;
  //    z-index: 10;
  //    opacity: 0;
  //  }
  //  &:hover {
  //    &:before{
  //      opacity: 1;
  //    }
  //  }
  //}
}

.analytics-d-table-row-col {
  flex: 1;
  min-width: 1rem;
  //z-index: 10;
  position: relative;
  transition: transform 380ms ease-in-out;
}

.analytics-d-table-row-col-title {
  font-size: 1.2rem;
  line-height: 1.7rem;
  text-transform: uppercase;
  letter-spacing: 0.54px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  color: rgba(var(--background), 1);
  font-weight: 510;
}

.analytics-d-address-cnt {
  display: flex;
  align-items: center;
  gap: 1.4rem;
  width: 100%;
  min-width: 0;

  &.inactive {
    .analytics-d-home-img-holder {
      &:before {
        content: "";
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        z-index: 10;
        background: #ffffffb3;
      }
    }

    .analytics-d-home-img {
      filter: grayscale(1);
    }
  }
}

.analytics-d-home-img-holder {
  position: relative;
  flex: 0 0 auto;
}

.analytics-d-home-img {
  flex: 0 0 auto;
  display: block;
  width: 6.4rem;
  height: 4.8rem;
  object-fit: cover;
}

.analytics-d-address-info {
  flex: 1;
  min-width: 0;

  > p {
    font-size: 1.4rem;
    line-height: 1.8rem;
    color: rgba(var(--background), 1);
    margin-bottom: 0.3rem;
    padding-right: 3rem;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  > span {
    font-size: 1.1rem;
    line-height: 1.5rem;
    background-color: rgba(#100b28, 0.04);
    color: rgba(#100b28, 0.72);
    padding: 0 0.4rem;
    letter-spacing: 0.55px;
  }

  span.font-medium {
    font-weight: 510;
  }
}

.analytics-d-table-text {
  font-size: 1.5rem;
  line-height: 1.9rem;
  color: rgba(var(--background), 1);
  word-break: break-word;
  padding-right: 4px;
  //text-overflow: ellipsis;
  //overflow: hidden;
  //white-space: nowrap;
}

.hidden-analytics-text {
  display: block;
}

.swipe-buttons {
  display: none;
  align-items: center;
  gap: 0.8rem;
}

.swipe-button {
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  background: none;
  width: 3.4rem;
  height: 3.4rem;
  border: 0.1rem solid rgba(var(--background), 0.16);
  font-size: 1.2rem;
  color: rgba(var(--background), 1);
  cursor: pointer;
  transition: background-color 240ms;

  &:hover {
    background-color: #fcfcfc;
  }

  &.disabled,
  &:disabled {
    opacity: 0.7;
    pointer-events: none;
  }

  &:active {
    transform: scale(0.98);
  }
}

.total-promote-buttons {
  display: flex;
  align-items: center;
  gap: 1.2rem;
  width: 100%;

  .button {
    flex: 1;
    height: 3.2rem;
    padding: 0 0.4rem;
    font-size: 1.5rem;
    line-height: 1.9rem;
    text-transform: capitalize;
    border: 0;
  }
}

.total-promote-button {
  flex: 1;
  outline: none;
  border: none;
  height: 3.2rem;
  padding: 0 0.4rem;
  color: #ffffff;
  font-size: 1.5rem;
  line-height: 1.9rem;
  text-transform: capitalize;
  cursor: pointer;
  font-weight: inherit;

  &.type-1 {
    background-color: rgba(var(--button-total-traffic), 1);
  }

  &.type-2 {
    background-color: rgba(var(--brand-color), 1);
  }

  &.type-3 {
    background-color: rgba(var(--button-ad-performance), 1);
  }

  &.type-4 {
    background-color: #66c3d7;
  }

  &.type-5 {
    background-color: #fafafa;
    border: 0.1rem solid #d6d5da;
    color: rgba(#100b28, 0.64);
    cursor: default;
    pointer-events: none;
  }

  &:active {
    transform: scale(0.98);
  }

  &.disabled {
    opacity: 0.3;
    pointer-events: none;
  }

  &.noneEvent {
    pointer-events: none;
  }
}

.analytics-d-table-status {
  display: flex;
  align-items: center;
  gap: 0.8rem;

  &.no-ads {
    .analytics-d-table-status-circle {
      background-color: rgba(var(--status-no-ad), 1);
    }
  }

  &.running {
    .analytics-d-table-status-circle {
      background-color: rgba(var(--status-running), 1);
    }
  }

  &.ended {
    .analytics-d-table-status-circle {
      background-color: rgba(var(--status-ended), 1);
    }
  }
}

.analytics-d-table-status-circle {
  flex: 0 0 auto;
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  background-color: rgba(#000000, 0.1);
}

.analytics-d-total-text {
  font-size: 1.5rem;
  line-height: 1.9rem;
  color: rgba(var(--background), 1);
  font-weight: 510;
  word-break: break-all;
  max-width: 100%;
  display: block;
}

@media screen and (max-width: 1610px) and (min-width: 981px) {
  .analytics-dashboard {
    .container {
      max-width: 131.6rem;
    }

    .footer {
      padding: 4rem 0 3.2rem 0;
    }
  }
  .analytics-d-head-inner {
    padding: 0 3rem;
  }
  .analytics-d-head {
    height: 10.6rem;
  }
  .analytics-d-head-title {
    font-size: 3.2rem;
    line-height: 4.1rem;
  }
  .analytics-d-content {
    padding: 3.3rem 2.4rem 0 2.5rem;
  }
  .analytics-d-table-holder-head {
    padding: 3.2rem 3.2rem 1.8rem 3.2rem;
  }
  .analytics-d-table-title {
    font-size: 1.8rem;
    line-height: 2.3rem;
    margin-bottom: 1.4rem;
  }
  .analytics-d-table-tab {
    height: 2.8rem;
    padding: 0 1.1rem;

    > p {
      font-size: 1.3rem;
      line-height: 1.7rem;
      margin-right: 0.6rem;
    }
  }
  .analytics-d-table-column {
    &.left {
      width: 30.4rem;
      padding-left: 1.6rem;

      .analytics-d-table-row {
        padding-left: 1.6rem;
      }
    }

    &.right {
      width: 31.2rem;
      padding-right: 1.6rem;

      .analytics-d-table-row {
        padding-right: 1.6rem;
      }
    }
  }
  .analytics-d-table-row {
    height: 7rem;

    &.head {
      height: 4.6rem;
    }
  }
  .analytics-d-table-sort {
    height: 4rem;
    bottom: -1.5rem;

    > i {
      font-size: 1.6rem;
    }

    > input {
      font-size: 1.3rem;
    }
  }
  .analytics-d-address-cnt {
    gap: 1.4rem;
  }
  .analytics-d-home-img {
    width: 5.6rem;
    height: 4.2rem;
  }
  .analytics-d-address-info {
    > p {
      font-size: 1.2rem;
      line-height: 1.5rem;
    }

    > span {
      font-size: 0.9rem;
      line-height: 1.1rem;
    }
  }
  .analytics-d-table-text {
    font-size: 1.3rem;
    line-height: 1.7rem;
  }
  .total-promote-buttons {
    gap: 1.2rem;

    > button {
      height: 3.2rem !important;
      font-size: 1.3rem !important;
    }
  }
  .analytics-d-total-text {
    font-size: 1.3rem;
    line-height: 1.7rem;
    font-weight: 510;
  }
  .analytics-d-table-row-col-title {
    font-size: 1.1rem;
    line-height: 1.4rem;
  }
}

@media screen and (max-width: 1440px) and (min-width: 980px) {
  .analytics-dashboard {
    .container {
      max-width: 100%;
    }
  }
  .analytics-d-table-row {
    height: 8.4rem;

    &.head {
      height: 6rem;
    }
  }
}

@media screen and (max-width: 1199px) {
  .analytics-d-table-row-col-title {
    > span {
      display: none;
    }
  }
  .total-promote-buttons {
    display: block;

    button {
      display: block;
      width: 100%;

      &:not(:first-child) {
        margin-top: 0.4rem;
      }
    }
  }
  .analytics-d-table-column {
    &.right {
      width: 14.3rem;
      padding-right: 0;

      .analytics-d-table-row {
        padding-right: 2.3rem;
      }
    }
  }
}

@media screen and (max-width: 980px) {
  .analytics-dashboard {
    .footer {
      padding: 3.3rem 0 0.6rem 0;
    }
  }
  .analytics-d-content {
    padding: 2.4rem;
  }
  .analytics-d-head-title {
    font-size: 3.5rem;
    line-height: 4.4rem;
  }
  .analytics-d-table {
    padding: 0;
  }
  .analytics-d-table-holder-head {
    display: block;
    padding: 0;
  }
  .analytics-d-table-holder-head-column {
    &:first-child {
      padding: 2.8rem 2.4rem 1.4rem 2.4rem;
    }
    &:last-child {
      height: 4.8rem;
    }
  }
  .analytics-d-table-sort {
    position: static;
    height: 100%;
    width: 100% !important;
    border-color: #ecebee;
    border-left: 0;
    border-right: 0;
    > input {
      transform: initial !important;
      padding-left: 5rem;
    }
    > i {
      width: 5rem;
      padding-left: 1.6rem;
    }
  }
  .analytics-d-table-holder-tabs {
    gap: 0.8rem;
  }
  .analytics-d-table-tab {
    padding: 0 0.8rem;
    height: 2.9rem;
  }
  .analytics-d-table-sort {
    &.open {
      width: 22rem;
    }
  }
  .swipe-cnt {
    width: min-content;
    // min-width: 100%;
    overflow-x: scroll;

    &.swipe {
      .analytics-d-table-row-col {
        transform: translate(-17rem, 0);
      }
    }
  }
  .analytics-d-table-column {
    &.left {
      width: 23.4rem;
      padding-left: 0;

      .analytics-d-table-row {
        padding-left: 2.3rem;
      }
    }

    &.center {
      overflow: hidden;

      .analytics-d-table-row-col {
        &:first-child {
          min-width: 8rem;
        }

        &:nth-child(2) {
          min-width: 8rem;
        }

        &:nth-child(3) {
          min-width: 7rem;
        }

        &:nth-child(4) {
          min-width: 8rem;
          max-width: 10rem;
          padding-right: 2rem;
          flex: 0 0 auto;
        }

        &:nth-child(5) {
          min-width: 9rem;
        }

        &:nth-child(6) {
          min-width: 7rem;
        }
      }
    }
  }
  .analytics-d-table-text {
    font-size: 1.3rem;
    line-height: 1.7rem;
  }
  .total-promote-button,
  .total-promote-buttons .button {
    // esi janamankovrem drel minjev dzenq Nar
    height: 2.8rem;
    font-size: 1.3rem;
    line-height: 1.7rem;
    width: 100%;
  }
  .analytics-d-address-info {
    > p {
      font-size: 1.4rem;
      line-height: 1.8rem;
    }
  }
}

// analytics dashboard WEB END
// analytics dashboard Mobile START
.analytics-d-mobile-table {
  background-color: #ffffff;
  box-shadow: 0 0.2rem 0.8rem #f0f0f0;
  min-width: 0;
  min-height: 0;
  border: 0.1rem solid #efefef;
}

.analytics-d-mobile-table-row {
  width: 100%;
  border-bottom: 0.1rem solid #ecebee;
  transform: translate3d(0, 0, 0);

  &.no-ads {
    .analytics-d-mobile-t-status {
      > span {
        background-color: #989ba1;
      }
    }
  }

  &.running {
    .analytics-d-mobile-t-status {
      > span {
        background-color: rgba(var(--brand-color), 1);
      }
    }
  }

  &.ended {
    .analytics-d-mobile-t-status {
      > span {
        background-color: rgba(var(--background), 1);
      }
    }
  }

  &.active {
    .analytics-d-mobile-t-status {
      > span {
        background-color: #009cbd;
      }
    }
  }

  &.open {
    .analytics-d-mobile-table-row-content {
      display: block;
    }

    .analytics-d-mobile-t-status {
      display: none;
    }

    .analytics-d-address-info {
      > span {
        display: inline-block;
      }
    }
  }
}

.analytics-d-mobile-table-row-head {
  display: flex;
  align-items: center;
  gap: 1rem;
  justify-content: space-between;
  min-height: 6rem;
  padding: 1.2rem 2rem;
  cursor: pointer;
}

.analytics-d-mobile-t-status {
  flex: 0 0 auto;
  width: 2rem;
  height: 2rem;
  display: flex;
  padding: 0.4rem;
  background-color: #ffffff;
  box-shadow: 0 0.3rem 0.6rem #00000024;
  border-radius: 50%;

  > span {
    display: block;
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }
}

.analytics-d-mobile-table-row-content {
  display: none;
  width: 100%;
}

.analytics-d-mobile-table-row-content-inner {
  display: grid;
  grid-template-columns: 6fr 6fr;
  grid-gap: 1.4rem;
  padding: 2rem 2rem 1.4rem 2rem;
}

.analytics-d-mobile-table-row-column {
  width: 100%;

  > span {
    display: block;
    font-size: 1.1rem;
    font-weight: 510;
    line-height: 1.3rem;
    margin-bottom: 0.6rem;
    color: rgba(#100b28, 0.56);
    letter-spacing: 0.5px;
    text-transform: uppercase;
  }

  > p,
  .analytics-d-table-text {
    font-size: 1.4rem;
    line-height: 1.8rem;
    color: rgba(var(--background), 1);
  }
}

@media screen and (max-width: 980px) {
  .analytics-d-table-holder-content {
    display: none;
  }
  .analytics-dashboard-mobile {
    display: grid;
    height: 100%;
    grid-template-rows: 11fr auto;
    min-width: 0;
    overflow: hidden;
  }
  .hidden-analytics-text {
    display: none;
  }
  .swipe-buttons {
    display: flex;
  }
  .your-listing-analytics-address {
    max-width: 100%;
  }
  .analytics-dashboard {
    .analytics-d-content {
      //max-width: calc(100% - 4.6rem);
      margin: 0 auto;
    }
  }
  .analytics-page-content-inner {
    max-width: 72rem;
    margin: 0 auto;
  }
}

// only Tablet
@media (min-width: 768px) and (max-width: 980px) {
  .analytics-dashboard {
    .analytics-dashboard-mobile {
      .analytics-d-mobile-table-row-content-inner {
        display: flex;
        padding-top: 0;
        padding-bottom: 1.6rem;
      }
      .total-promote-buttons {
        padding: 0 2.3rem 2.1rem;
        display: flex;
        .total-promote-button {
          height: 4rem;
          margin-top: 0;
        }
      }
      .analytics-d-mobile-table-row-head {
        padding-top: 1.6rem;
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .analytics-dashboard-mobile {
    display: grid;
    height: 100%;
    grid-template-rows: 11fr auto;
    min-width: 0;
    overflow: hidden;
  }
  .analytics-dashboard {
    height: auto;
    min-height: 100%;

    .footer {
      padding: 2rem 0 0 0;
    }

    .analytics-d-content {
      display: block;
      padding: 1.6rem;
      height: 100%;
      overflow: hidden;
      //max-width: calc(100% - 3.2rem);
    }
  }
  .analytics-d-head {
    height: 8rem;
    padding: 2rem 1.6rem;
  }
  .analytics-d-head-title {
    font-size: 2.2rem;
    line-height: 2.8rem;
  }
  .analytics-d-table-holder-head {
    display: block;
    padding: 0;
    box-shadow: 0 0.2rem 0.8rem #f0f0f0;
    border-left: 0.1rem solid #efefef;
    border-right: 0.1rem solid #efefef;
    border-top: 0.1rem solid #efefef;
    border-bottom: 0;
  }

  .analytics-d-table-mobile-dropdown {
    padding: 2.4rem 2rem;
    max-height: 6.6rem;
    overflow: hidden;
    transition: max-height 380ms ease-in-out;

    &.open {
      max-height: 30rem;

      .analytics-d-table-title {
        &:before {
          transform: rotateX(180deg);
        }
      }
    }
  }
  .analytics-d-table-title {
    font-size: 1.7rem;
    line-height: 2.2rem;
    position: relative;

    &:before {
      display: block;
      content: "\e901";
      font-family: "icomoon";
      color: #6d717a;
      font-size: 0.8rem;
      position: absolute;
      right: 0;
      top: 0;
      transition: transform 240ms;
    }
  }
  .analytics-d-table-holder-head-column {
    &:first-child {
      padding: 0;
    }
  }
  .analytics-d-table-sort {
    position: relative;
    width: 100% !important;
    height: 4.8rem;
    bottom: 0;
    background-color: #ffffff !important;
    border-top: 0.1rem solid #ecebee !important;
    border-bottom: 0 !important;

    &.open {
      width: 100%;
      border: 0;
    }

    > input {
      padding: 0 1rem 0 5rem;
      transform: none !important;
    }

    > i {
      width: 5.4rem;
    }
  }
  //
  .analytics-d-address-cnt {
    grid-gap: 1rem;
  }
  .analytics-d-home-img {
    width: 4.8rem;
    height: 3.6rem;
  }
  .analytics-d-address-info {
    p {
      margin-bottom: 0;
    }

    > span {
      display: none;
    }
  }
  .total-promote-buttons {
    gap: 1rem;
    padding: 0 2rem 2rem 2rem;

    .button {
      height: 3.2rem;
      font-size: 1.5rem;
    }
  }
  .total-promote-button {
    height: 3.2rem;
    font-size: 1.5rem;
  }
}

@media screen and (max-width: 768px) {
  .analytics-d-address-info {
    & > span {
      font-size: 1rem;
    }
  }
}

// analytics dashboard Mobile END
