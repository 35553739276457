@media screen and (max-width: 1919px) {
  .choose-listing-head-choose-column {
    gap: 1.6rem;
    min-width: 29.6rem;
  }
  .choose-listing-s-i-column-left {
    margin-right: 2.8rem;
  }
  .choose-listing-head-images-column {
    width: 5.6rem;
    height: 4.2rem;

    &:not(:last-child) {
      margin-right: 1rem;
    }
  }
}
@media screen and (max-width: 1610px) {
  .container {
    max-width: 138rem;
  }

  // lusting START
  .listing-page-home-cards {
    margin-top: 2.2rem;
    grid-template-columns: repeat(auto-fill, minmax(27.6rem, 1fr));
    grid-column-gap: 3.2rem;
    grid-row-gap: 2.8rem;
  }
  // listing END
  .promote-listing-empty-button {
    max-width: 15.2rem;
  }
  .promote-listing-empty-text {
    font-size: 1.2rem;
    line-height: 2rem;
    max-width: 22rem;
  }
  .choose-listing-head-no-images {
    font-size: 1rem;
  }
  .choose-listing-head-images {
    margin-right: 2.4rem;
  }
  .choose-listing-head-info {
    font-size: 1.2rem;
    line-height: 1.5rem;
  }
  .client-finder-tooltip {
    margin: 0 0.6rem;
  }
  // choose listing images step END
  // select paid media package START
  .select-paid-media-cards {
    grid-template-columns: repeat(auto-fill, minmax(25.6rem, 1fr));
    grid-gap: 2.8rem;
  }
  .select-paid-media-card {
    padding: 2.4rem 3.6rem 2.5rem 3.6rem;
  }
  .select-paid-package-name {
    font-size: 1.8rem;
  }
  .select-paid-package-price {
    font-size: 4.2rem;
    line-height: 5.3rem;
    transform: translate(2px, 2px);
  }
  .select-paid-package-day {
    font-size: 1.3rem;
    line-height: 1.6rem;
  }
  .select-paid-package-reached {
    font-size: 1.2rem;
    line-height: 1.5rem;
  }
  .select-paid-package-name-row {
    margin-bottom: 2rem;
  }
  .select-paid-package-price-row {
    margin-bottom: 3.4rem;
  }
  // select paid media package END
  // review copy START
  .review-copy-s-img-block {
    width: 4rem;
    height: 4rem;
  }
  .review-copy-social-row-name {
    font-size: 0.9rem;
  }
  .review-copy-s-plus {
    margin: 0 0.7rem;
    height: 4rem;
  }
  // review copy END
  //analytics START
  .analytics-page {
    &.footer {
      padding: 4.5rem 0 3.6rem 0;
    }
  }
  // this part css hide  year in datepicker input
  // .datepicker-text {
  //   span {
  //     display: none;
  //   }
  // }
  //analytics END
  // your ad details START
  .map-chart-table {
    padding-right: 2.6rem;
  }
  .map-chart-content-column {
    &.chart-column {
      width: 54rem;
      padding: 4.8rem 4rem 3rem 5rem;
    }
    &.table-column {
      max-height: 405px;
    }
  }
  .map-chart-table-row {
    &.head {
      background-color: #ffffff;
    }
  }
  // your ad details END

  // listing traffic start
  .traffic-card {
    &-body {
      //height: 248px;
      align-items: flex-start;
      padding: 2rem 3.2rem 2.4rem 3.2rem;
    }
  }
  .traffic-card-head-subtitle,
  .reach-and-clicks-text {
    font-size: 1.2rem;
    line-height: 1.8rem;
    color: rgba(var(--background), 0.64);
  }
  .reach-and-clicks-title,
  .traffic-card-head-title,
  .your-ad-details-min-title {
    font-size: 1.6rem;
    line-height: 2rem;
    margin-bottom: 0;
  }
  .empty-card {
    .empty-text {
      font-size: 1.3rem;
      line-height: 1.7rem;
    }
    .empty-icon {
      font-size: 4rem;
    }
  }

  .your-ad-details-head-link {
    font-size: 1.3rem;
    line-height: 1.6rem;
    padding-bottom: 0;
  }
  .list-item-dot {
    width: 0.9rem;
    height: 0.9rem;
    margin-right: 1rem;
  }
  .traffic-card-head,
  .map-chart-head,
  .reports-head {
    padding: 2.5rem 3.2rem 2.2rem 3.1rem;
  }
  .area-legends-item {
    margin: 0 6px;
  }
  .area-legends-item-text {
    font-size: 1.2rem;
    line-height: 1.6rem;
  }
  .pie-chart {
    width: 25rem;
    height: 25rem;
    margin-right: 2rem;
  }
  .map-chart-point {
    font-size: 1.2rem;
    line-height: 1.5rem;
  }
  .map-chart-gr {
    height: 1.3rem;
    width: 12.7rem;
  }
  .map-chart-table-row {
    height: 4.8rem;
    &.head {
      height: 4.8rem;
      .map-chart-table-text {
        font-size: 1.1rem;
      }
    }
  }
  // listing traffic END
  .reports-head-typ {
    grid-auto-flow: row;
    grid-gap: 0.8rem;
  }
  // listing page START
  .presentation-row {
    padding: 1.8rem 2.1rem 2.2rem 2.1rem;
  }
  .presentation-row-texts {
    padding-left: 6.5rem;
  }
  .presentation-row-logo {
    width: 5.2rem;
    height: 5.2rem;
  }
  .presentation-user-name {
    font-size: 3.8rem;
    line-height: 4.8rem;
    margin-bottom: 0;
  }
  .presentation-info {
    font-size: 1.4rem;
    line-height: 1.8rem;
  }
  // listing page END
  //  your ad details START
  .your-ad-details {
    .container {
      max-width: 126.8rem;
    }
    .footer {
      padding: 4rem 0 3.2rem 0;
    }
  }
  .your-ad-details-head {
    padding: 1.9rem 2rem;
  }
  .your-ad-details-head-address {
    font-size: 2rem;
    line-height: 2.5rem;
    margin-bottom: 0;
    font-weight: 600;
  }
  .your-ad-details-content-title {
    font-size: 1.8rem;
    line-height: 2.3rem;
  }
  .your-ad-details-content-head {
    padding: 2.4rem 0 1.6rem 0;
  }
  .details {
    .your-ad-details-content-head {
      padding: 3.3rem 0 2.7rem 0;
    }
  }
  .your-ad-details-info-row {
    grid-gap: 2.8rem;
    margin-bottom: 2.8rem;
  }
  .your-ad-details-head-info-title {
    font-size: 1.1rem;
    line-height: 1.4rem;
  }

  .your-ad-details-min-info {
    top: -0.2rem;
    right: -0.7rem;
  }
  .your-ad-details-head-info-title-text {
    font-size: 1.3rem;
    line-height: 1.6rem;
    min-height: 1.6rem;
  }
  .your-ad-details-point {
    font-size: 4rem;
    line-height: 4.6rem;
    letter-spacing: 0.6px;
    padding-top: 0.6rem;
  }
  .back-to-all-ads-btn {
    font-size: 1.4rem;
  }
  .your-ad-details-info-column {
    min-height: 9.8rem;
    padding: 2.3rem 3.2rem 1.6rem 2.2rem;
  }
  .your-ad-details-info-row {
    &.full {
      .your-ad-details-info-column {
        padding: 2.3rem 3.2rem 1.4rem 3rem;
      }
    }
  }

  //  your ad details END
  .map-chart-row,
  .traffic-row {
    margin-bottom: 2.8rem;
  }
  .traffic-row,
  .reports-row {
    grid-gap: 2.8rem;
  }
  .reports-content-table-total-text {
    font-size: 1.4rem;
    line-height: 1.8rem;
  }
  .choose-listing-content-tabs-column-tab {
    font-size: 1.6rem;
    line-height: 2rem;
  }
  .choose-listing-content-tabs-column {
    grid-column-gap: 2.4rem;
  }
  .preview-your-ad-popup-close {
    width: 3.6rem;
    height: 3.6rem;
    font-size: 1rem;
    right: 2.4rem;
    top: 2.4rem;
  }
  //confirmation START
  .confirmation-container {
    padding-top: 4rem;
  }
  .submitted-block,
  .pdf-download {
    max-width: 29.6rem;
  }
  .submitted-block {
    padding: 3.2rem;
    min-height: 19.6rem;
  }
  .submitted-price-row {
    height: 4.6rem;
  }
  .submitted-price-icon {
    width: 1.4rem;
    height: 1.4rem;
    font-size: 0.6rem;
    margin-right: 0.8rem;
  }
  .submitted-name {
    font-size: 1.4rem;
    line-height: 1.8rem;
  }
  .submitted-price {
    font-size: 1.6rem;
    line-height: 2rem;
  }
  .submitted-row-info-text {
    font-size: 1.1rem;
    line-height: 1.4rem;
  }
  .go-back-listing {
    > p {
      margin-bottom: 0;
    }
  }
  //confirmation END
  // share with client POPUP START
  .share-with-client-popup {
    width: 100%;
    max-width: 480px;
    .tag-input {
      font-size: 13px;
    }
    .salutation-inner-hello {
      font-size: 12px;
    }
  }
  .share-with-client-content-header {
    padding: 21px 30px;
  }
  .share-with-client-title {
    font-size: 18px;
    line-height: 23px;
  }
  .share-with-client-close {
    font-size: 10px;
  }
  .share-with-client-content-inner {
    padding: 18px 30px 0;
  }
  .share-with-client-popup-description {
    font-size: 13px;
    padding-bottom: 16px;
  }
  .share-with-client-popup-input-label {
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 8px;
  }
  .share-with-client-add-recipient {
    font-size: 12px;
  }
  .share-with-client-input {
    font-size: 13px;
    min-height: 200px;
  }
  .share-with-client-button {
    height: 40px;
    font-size: 13px;
  }
  .share-with-client-content-footer {
    padding: 13px 0 24px 0;
    > p {
      font-size: 9px;
      line-height: 12px;
    }
  }

  .share-with-client-label {
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 0.24px;
  }
  .checkbox-icon {
    width: 14px !important;
    height: 14px !important;
    > i {
      font-size: 8px !important;
      // sxala senc anel@
      height: 6px;
    }
  }
  // share with client POPUP END
  // no result START
  .no-result-content {
    max-width: 38rem;
  }
  .no-result-img {
    max-width: 5.6rem;
    max-height: 5.6rem;
    margin-bottom: 1.6rem;
  }
  .no-result-title {
    font-size: 1.8rem;
    line-height: 2rem;
  }
  .no-result-info {
    font-size: 1.3rem;
    line-height: 2.1rem;
  }
  // no result END
  // permission issue START
  .permission-issue-content {
    max-width: 36.8rem;
  }
  .permission-issue-img {
    max-width: 23.2rem;
  }
  .permission-issue-shadow {
    width: 140px;
    margin: 3.2rem 0;
  }
  .permission-issue-head {
    padding-bottom: 3.8rem;
    margin-bottom: 3.3rem;
  }
  .permission-issue-title {
    font-size: 3.2rem;
    line-height: 4rem;
    margin-bottom: 1.1rem;
  }
  .permission-issue-text {
    font-size: 1.6rem;
    line-height: 2.8rem;
  }
  .permission-issue-b-text {
    font-size: 1.5rem;
    line-height: 2.5rem;
  }
  // permission issue END
  .pie-list {
    max-height: 21rem;
    .list-item {
      height: 34px;
    }
  }
}

@media screen and (min-width: 768px) and (max-width: 1919px) {
  .choose-listing-images-view-step {
    .steps-bottom {
      display: flex;
      align-items: center;
      padding: 1rem 0;
      height: 11rem;
      min-height: initial;
    }
  }
}
@media screen and (max-width: 1610px) and (min-width: 980px) {
  .reports-content-table-row.head {
    height: 4.6rem;
    margin-bottom: 1.6rem;
  }
  .reports-content-table-scrollable {
    //overflow-y: auto;
    height: auto;
    max-height: 27.4rem;
  }
  .reports-content-table-row {
    margin-bottom: 0.9rem;
    padding-left: 4.4rem;
    padding-right: 4.4rem;
    .reports-content-table-text {
      font-size: 1.4rem;
    }
  }
  .reports-content-table-total {
    padding: 2rem 4.4rem 2.4rem;
  }
  .reports-content-table-column:first-child {
    width: 25%;
  }
  // listing START
  .sorting-dropdown-c-row {
    font-size: 1.2rem;
  }
  // listing END
  // steps START
  .step-name-container {
    margin-top: -1rem;
  }
  .step-info-row {
    padding: 2.1rem 2.8rem 1rem 3.2rem;
    min-height: 11rem;
  }
  .steps-custom-radio-button {
    width: 1.8rem;
    height: 1.8rem;
    margin-right: 1.2rem;
  }
  .step-info-title {
    font-size: 1.3rem;
    line-height: 1.6rem;
    letter-spacing: 1.56px;
  }
  .step-name {
    font-size: 3.8rem;
  }
  .step-arrow {
    width: 3.6rem;
    height: 3.6rem;
    font-size: 1.3rem;
  }
  .step-cnt {
    padding: 4rem 4.8rem 3.2rem 4.8rem;
  }
  .select-your-goal-step {
    padding-top: 4rem;
    padding-right: 5.6rem;
  }
  .step-info-row-content-inner {
    align-items: center;
  }
  .select-your-goal-label {
    //margin-bottom: 2.4rem;
    margin-bottom: 2rem;
  }
  .next-step-button-block {
    gap: 1.6rem;
    min-width: 24.2rem;
    .button {
      &:first-child {
        flex: 0 0 auto;
        width: 10.6rem;
      }
    }
  }
  .select-your-goal-form {
    max-width: 73rem;
  }
  .select-your-goal-name {
    font-size: 1.4rem;
    line-height: 1.8rem;
  }
  .select-your-goal-info {
    font-size: 1.4rem;
    line-height: 2.4rem;
  }
  .select-your-goal-label-head {
    //margin-bottom: 1.2rem;
    margin-bottom: 1rem;
  }
  .steps-count-title {
    font-size: 1.4rem;
    line-height: 1.8rem;
    min-height: 1.8rem;
  }
  .choose-your-media-step-title {
    font-size: 1.4rem;
    line-height: 2.4rem;
    max-width: 48rem;
  }
  .steps-count-items {
    margin: 1.2rem 0 1rem 0;
  }
  .preview-your-ad {
    .preview-your-ad-popup-card {
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
    }
    .preview-your-ad-holder {
      flex: 0 0 auto;
      margin: 0 !important;
    }
  }
  .preview-your-ad-content-column-row-cnt {
    .review-copy-radio-text {
      font-size: 1.4rem;
      line-height: 1.8rem;
    }
    .review-copy-input-r-title {
      font-size: 1.2rem;
    }
  }
  .preview-social-tabs {
    .analytics-page-tab {
      font-size: 1.4rem;
      line-height: 1.8rem;
      padding-bottom: 1.1rem;
    }
    .analytics-page-tabs-column {
      grid-gap: 2.2rem;
    }
  }
  .preview-your-ad-info-text {
    font-size: 1.4rem;
    line-height: 2.8rem;
  }
  .preview-social-tabs-title {
    margin-bottom: 0.3rem;
  }
  .review-copy-inputs-column {
    width: 43.2rem;
    .steps-custom-radio-button {
      margin-right: 0.8rem;
    }
  }
  .review-copy-input-row {
    margin-bottom: 2rem;
  }
  .review-copy-radio-text {
    font-size: 1.4rem;
    line-height: 1.8rem;
  }
  .review-copy-input {
    font-size: 1.4rem;
    line-height: 1.6rem;
    max-width: 100%;
    min-width: 29.6rem;
  }
  .custom-editable-text {
    font-size: 1.4rem;
    line-height: 2.3rem;
    margin-bottom: 0.6rem;
  }
  .social-sites-row {
    padding-top: 2.4rem;
  }
  .choose-listing-content-inner {
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 2.8rem;
  }
  .choose-listing-card {
    height: 22.2rem;

    &.active {
      padding: 1.2rem;
    }
  }
  .choose-listing-card-count {
    min-width: 2rem;
    height: 2rem;
    line-height: 2.2rem;
    font-size: 1.1rem;
    top: 1.8rem;
    left: 0.9rem;
  }
  .review-copy-step {
    padding-top: 4rem;
  }
  .review-copy-social-row {
    margin-bottom: 3rem;
  }
  .review-copy-input-r-title {
    font-size: 1.2rem;
    line-height: 2rem;
    letter-spacing: 0.19px;
    margin-bottom: 0.8rem;
  }
  .review-copy-input-r-latter-count {
    font-size: 1rem;
  }
  .choose-listing-content-row {
    padding: 1.6rem 0 2.4rem 0;
  }
  .choose-listing-s-i-column-left {
    margin-right: 2.8rem;
  }
  .choose-listing-head-choose-column {
    min-width: 29.6rem;
    gap: 1.6rem;
    .button {
      &:first-child {
        flex: 0 0 auto;
        width: 10.6rem;
      }
    }
  }
  .client-finder-tooltip-content {
    width: 16rem;
    padding: 1.1rem;
    font-size: 1.1rem;
    line-height: 1.6rem;
    &:before {
      width: 1.4rem;
      height: 1.4rem;
      bottom: -0.6rem;
    }
    > p {
      letter-spacing: 0.17px;
    }
  }
  // steps END
  // selected paid START
  .select-paid-media-title {
    margin-bottom: 3.8rem;
    font-size: 1.4rem;
    line-height: 1.8rem;
    > span {
      margin-right: 0.3rem;
    }
  }
  .select-paid-media-car-icon {
    font-size: 2rem;
    right: -0.8rem;
    top: 0.3rem;
  }
  .payment-popup-close {
    width: 3.6rem;
    height: 3.6rem;
    font-size: 1rem;
    right: 2.4rem;
    top: 2.1rem;
  }
  .confirmation-title {
    margin-bottom: 4.5rem;
    font-size: 1.4rem;
    line-height: 1.8rem;
  }
  .confirmation-wrapper {
    .next-step-right-column {
      width: 188px;
    }
  }
  .submitted-block {
    padding-top: 3.4rem;
    padding-left: 3rem;
    padding-right: 3.5rem;
  }
  .go-back-listing {
    font-size: 1.4rem;

    > i {
      font-size: 3rem;
      line-height: 1.5rem;
    }
  }
  // selected paid END
  .steps-count-item {
    width: 3.6rem;
    height: 0.3rem;

    &:not(:last-child) {
      margin-right: 0.3rem;
    }
  }
  .social-site-column {
    padding: 2.8rem 2.3rem 1.3rem 2.4rem;
    max-width: 24.3rem;
  }
  .social-site-c-inner-column {
    &.plus {
      padding: 0 1.5rem;
    }
  }
  .social-site-choose {
    font-size: 1.1rem;
    line-height: 1.4rem;
  }
  .social-site-c-plus {
    font-size: 0.8rem;
  }
  .social-site-images {
    width: 3.6rem;
    height: 3.6rem;
    margin-bottom: 0.9rem;
  }
  .social-site-name {
    font-size: 1.2rem;
    line-height: 1.6rem;
    font-weight: 510;
  }
  .social-popup {
    max-width: 35rem;
    grid-row-gap: 0.9rem;
  }
  .social-popup-head {
    padding: 1.5rem;
  }
  .social-popup-close {
    width: 3.6rem;
    height: 3.6rem;
    font-size: 1rem;
  }
  .social-popup-c-images-row {
    margin-bottom: 2rem;
  }
  .social-popup-c-images-row-inner {
    margin-bottom: 1.6rem;
  }
  .social-popup-content {
    padding: 0 4rem 1.4rem 4rem;
  }
  .social-popup-img {
    max-width: 4rem;
  }
  .social-popup-plus {
    font-size: 1.2rem;
    padding: 0 0.8rem;
  }
  .social-popup-media-name {
    font-size: 2rem;
    line-height: 2.4rem;
  }
  .social-popup-info {
    font-size: 1.3rem;
    line-height: 2.4rem;
  }
  // preview your AD in 5 step START
  .preview-your-ad-card-inner {
    &:before {
      height: 2.7rem;
    }
  }
  //carousel START
  .preview-your-ad-content {
    grid-gap: 4.8rem;
    .preview-your-ad-swiper-row {
      height: 44.4rem;
      width: 30rem;
    }
  }
  .preview-your-ad-block-head {
    padding: 0.8rem 1.5rem 1.3rem 1rem;
  }
  .preview-your-ad-logo-block {
    width: 3.5rem;
    height: 3.5rem;
    margin-right: 0.8rem;
  }
  .preview-your-ad-menu {
    font-size: 1.3rem;
  }
  .preview-your-ad-user-name {
    font-size: 1.2rem;
    line-height: 1.5rem;
    letter-spacing: -0.12px;
  }
  .preview-your-ad-sponsored-text {
    font-size: 1.1rem;
    line-height: 1.3rem;

    > i {
      font-size: 0.9rem;
    }
  }
  .preview-your-ad-slider-row {
    padding-bottom: 3rem;
    border-left: 1rem solid transparent;
    border-right: 1rem solid transparent;
  }
  .preview-your-ad-block-info {
    font-size: 1.3rem;
    line-height: 1.6rem;
    padding: 0 1rem;
    margin-bottom: 1.2rem;
    height: 3.1rem;
  }
  .preview-your-ad-card {
    width: 20.8rem;
    height: 27.7rem;
    margin-right: 1rem;
    &.full {
      .preview-your-ad-card-inner {
        padding: 2rem 0;
      }
    }
    &.has-header {
      .preview-your-ad-card-content {
        border-left: 1rem solid transparent;
        border-right: 1rem solid transparent;
      }

      .preview-your-ad-card-img {
        height: calc(100% + 1.4rem);
        transform: translate(0, -2.1rem);
      }
    }
  }
  .preview-your-ad-card-head {
    height: 6.7rem;
    padding: 0.8rem 0.9rem 2.7rem 0.9rem;
  }
  .preview-your-ad-card-user {
    margin-top: 0.2rem;
    margin-bottom: 0.3rem;
    font-size: 1.1rem;
    line-height: 1.3rem;
  }
  .preview-your-ad-block-bottom {
    padding: 0 0.9rem;
  }
  .preview-your-ad-card-user-info {
    font-size: 0.8rem;
    line-height: 1rem;
  }
  .preview-your-ad-card-head-logo {
    width: 3.2rem;
    height: 3.2rem;
    margin-left: 1rem;
  }
  .preview-your-ad-card-bottom {
    height: 6.6rem;
    padding: 0 1rem 0 0.7rem;
  }
  .preview-your-ad-card-bottom-column {
    > p {
      font-size: 1.2rem;
      line-height: 1.6rem;
      max-height: 3.2rem;
      transform: translate(0, -0.2rem);
    }

    > span {
      font-size: 1.2rem;
      line-height: 1.4rem;
      letter-spacing: 0.54px;
    }
  }
  .preview-your-ad-card-img {
    min-height: 15rem;
  }
  .preview-your-ad-card-button {
    min-width: 7.7rem;
    height: 3rem;
    font-size: 1.1rem;
  }
  .preview-your-ad-block-bottom-inner {
    padding: 1rem 0 1.3rem 0;
  }
  .preview-your-ad-block-bottom-column {
    transform: translate(-6px, -3px);
    > span {
      font-size: 1rem;
      line-height: 1.2rem;
    }

    > i {
      font-size: 1.5rem;
      margin-right: 0.6rem;
    }
  }
  .preview-your-ad-block {
    width: 28.7rem;
    transform: translate(-1px, 7px);
    .preview-your-ad-sponsored-text {
      > i {
        padding-left: 0.6rem;
        &:after {
          left: -0.5rem;
        }
      }
    }
  }
  //carousel END
  // single image START
  .preview-your-ad-block {
    &.single-image {
      width: 25.5rem;
      transform: translate(0, 0.4rem);
      .preview-your-ad-menu {
        transform: translate(2px, 5px);
        font-size: 1.3rem;
      }
      .preview-your-ad-card-bottom-column {
        transform: none;
      }
      .preview-your-ad-block-bottom-column {
        transform: translate(-7px, 1px);
      }
      .preview-your-ad-block-head {
        padding: 0.9rem 0.9rem 1rem 0.9rem;
      }
      .preview-your-ad-logo-block {
        width: 3.2rem;
        height: 3.2rem;
        margin-right: 0.6rem;
      }
      .preview-your-ad-user-name {
        font-size: 1rem;
        line-height: 1.2rem;
      }
      .preview-your-ad-sponsored-text {
        font-size: 0.9rem;
        line-height: 1.1rem;

        > i {
          font-size: 0.8rem;
        }
      }
      .preview-your-ad-block-info {
        height: 2.8rem;
        font-size: 1.1rem;
        line-height: 1.4rem;
        padding: 0 0.8rem;
        margin-bottom: 0.8rem;
      }
      .preview-your-ad-card {
        margin: 0;
        height: 31.8rem;
        &.has-header {
          .preview-your-ad-card-content {
            border-left: 1.3rem solid transparent;
            border-right: 1.3rem solid transparent;
          }
          .preview-your-ad-card-img{
            height: calc(100% + 0.7rem);
            transform: translate(0, -2rem);
          }
        }
        .preview-your-ad-card-bottom {
          padding: 0 1rem;
        }
      }
      .preview-your-ad-block-bottom {
        padding: 0 0.8rem;
      }
      .preview-your-ad-card-head {
        height: 8.1rem;
        padding: 0.9rem 1.3rem 3.5rem 1.3rem;
      }
      .preview-your-ad-card-user {
        margin-top: 0.4rem;
        font-size: 1.3rem;
        line-height: 1.5rem;
      }
      .preview-your-ad-slider-row {
        padding-bottom: 1.1rem;
        border: 0;
      }
      .preview-your-ad-card-user-info {
        font-size: 1rem;
        line-height: 1.2rem;
      }
      .preview-your-ad-card-head-logo {
        width: 3.7rem;
        height: 3.7rem;
      }
      .preview-your-ad-card-bottom-column {
        > p {
          font-size: 1.2rem;
          line-height: 1.4rem;
          max-height: 2.8rem;
        }

        > span {
          font-size: 0.9rem;
          line-height: 1rem;
          margin-bottom: 0.4rem;
          letter-spacing: 0;
        }
      }
      .preview-your-ad-card-button {
        height: 2.7rem;
        font-size: 1rem;
        min-width: 7.8rem;
        letter-spacing: 0.25px;
      }
      .preview-your-ad-block-bottom-column {
        > span {
          font-size: 0.9rem;
        }
      }
      .preview-your-ad-block-bottom-inner {
        padding: 0.6rem 0;
      }
    }
  }
  // single image END
  //instagram START
  .instagram-your-ad-block {
    width: 29.5rem;
    transform: translate(0, 5px);
    .preview-your-ad-card {
      width: 100%;
      height: 29.6rem;

      &.has-header {
        .preview-your-ad-card-content {
          border-left: 1.2rem solid transparent;
          border-right: 1.2rem solid transparent;
        }
        .preview-your-ad-card-img {
          height: calc(100% + 1.6rem);
          transform: translate(0, -2.8rem);
        }
      }
    }
    .preview-your-ad-card-head {
      height: 9.4rem;
      padding: 1.1rem 1.2rem 0 1.2rem;
      align-items: flex-start;
    }
    .preview-your-ad-card-user {
      margin-top: 0.4rem;
      font-size: 1.6rem;
      line-height: 1.8rem;
      margin-bottom: 0.4rem;
    }
    .preview-your-ad-card-user-info {
      font-size: 1rem;
      line-height: 1.2rem;
    }
    .preview-your-ad-card-head-logo {
      width: 4.5rem;
      height: 4.5rem;
    }
  }
  .instagram-your-ad-head {
    padding: 0.6rem 0.9rem;
    min-height: 3.7rem;
  }
  .instagram-your-ad-logo {
    width: 2.6rem;
    height: 2.6rem;
    margin-right: 0.7rem;
  }
  .instagram-your-ad-head-texts {
    > p {
      font-size: 1rem;
      line-height: 1.2rem;
    }

    > span {
      font-size: 0.9rem;
      line-height: 1.2rem;
    }
  }
  .instagram-shop-now-inner {
    padding: 1rem 0 1.1rem 0;

    > span {
      font-size: 1.1rem;
      line-height: 1.4rem;
    }

    > i {
      font-size: 1rem;
    }
  }
  .instagram-shop-now {
    margin-bottom: 0.8rem;
    padding: 0 0.9rem;
  }
  .instagram-info-row {
    font-size: 1.1rem;
    line-height: 1.5rem;
  }
  .instagram-your-ad-bottom {
    padding: 0 0.9rem 0 0.9rem;
  }
  .instagram-your-ad-icons-column {
    grid-gap: 1.3rem;
    transform: translate(1px, -1px);
  }
  .instagram-your-ad-icon {
    font-size: 1.8rem;
  }
  .instagram-your-ad-icons {
    padding-bottom: 0.8rem;
    > i {
      font-size: 1.6rem;
    }
  }
  //instagram END
  // story START
  .story-insta-your-ad-block {
    width: 24.9rem;
    transform: translate(0px, 4px);
    .preview-your-ad-card {
      width: 100%;
      height: 26rem;

      &.has-header {
        .preview-your-ad-card-content {
          border-left: 1.1rem solid transparent;
          border-right: 1.1rem solid transparent;
        }
        .preview-your-ad-card-img {
          height: calc(100% + 2.3rem);
          transform: translate(0, -2.3rem);
        }
      }
    }
    .preview-your-ad-card-user {
      margin-top: 0.2rem;
      margin-bottom: 0.4rem;
      font-size: 1.3rem;
      line-height: 1.5rem;
    }
    .preview-your-ad-card-head {
      height: 9rem;
      padding: 1.1rem 1.1rem 2.7rem 1.1rem;
      transform: translate(0, -1px);
    }
    .preview-your-ad-card-user-info {
      font-size: 0.9rem;
    }
    .preview-your-ad-card-head-logo {
      width: 3.8rem;
      height: 3.8rem;
    }
  }
  .story-insta-your-ad-head {
    height: 5.4rem;
    padding: 0.7rem 0.5rem 0 0.5rem;
  }
  .story-insta-your-ad-head-inner {
    padding: 0 0.2rem 0 0.6rem;
  }
  .story-insta-ad-logo {
    width: 2.2rem;
    height: 2.2rem;
    margin-right: 0.6rem;
  }
  .story-insta-ad-head-texts {
    > p {
      font-size: 0.9rem;
      letter-spacing: initial;
      line-height: 1.2rem;
      font-weight: bold;
    }

    > span {
      font-size: 0.8rem;
      line-height: 1rem;
      transform: translate(0px, -1px);
      > i {
        font-size: 0.4rem;
        display: inline-block;
        vertical-align: middle;
        height: 6px;
      }
    }
  }
  .story-insta-your-ad-icons-column {
    grid-gap: 1.4rem;
    transform: translate(0, -0.1rem);
    > i {
      font-size: 1.1rem;
    }
  }
  .story-insta-time-row {
    height: 0.1rem;
    margin-bottom: 0.7rem;
  }
  .story-insta-your-ad-bottom {
    height: 13.1rem;
    padding: 1.1rem 2.2rem 0.9rem 2rem;
  }
  .story-insta-your-ad-bottom-text {
    font-size: 1rem;
    line-height: 1.5rem;
  }
  .story-insta-your-ad-button-row {
    > i {
      font-size: 1.1rem;
      padding-bottom: 0.1rem;
      transform: translate(3px, 0);
    }

    > button {
      height: 3rem;
      min-width: 6.3rem;
      font-size: 0.9rem;
      letter-spacing: -0.14px;
    }
  }
  // story END
  // preview your AD in 5 step END
  .preview-social-tabs-container {
    margin-top: 3.9rem;
    margin-bottom: 3.5rem;
  }
  .preview-social-tabs-title {
    font-size: 1.2rem;
  }
  .review-copy-radio-buttons-row {
    .steps-custom-radio-button {
      margin-right: 0.8rem;
    }
  }
  .preview-your-ad-content-column-button {
    width: 26.6rem;
    gap: 1.6rem;
    .button {
      &:first-child {
        flex: 0 0 auto;
        width: 10.6rem;
      }
    }
  }
  .your-ad-details-head-button {
    height: 4.2rem;
    font-size: 1.3rem;
    line-height: 1.7rem;
    width: auto;
    min-width: 20.2rem;
    margin-right: 1.2rem;
  }
  .your-ad-details-download-button-icon {
    font-size: 4.2rem;
  }
  // preview your ad popup START
  .preview-your-ad-popup-card {
    .preview-your-ad-block {
      max-width: 31.7rem;
    }
    .step-name-container {
      top: -2rem;
      left: -1rem;
    }
    // carusel AD START
    .preview-your-ad-card {
      margin-right: 1rem;
      width: 23rem;
      height: 30.6rem;
      &.has-header {
        .preview-your-ad-card-content {
          border-left: 1.1rem solid transparent;
          border-right: 1.1rem solid transparent;
        }
      }
    }
    .preview-your-ad-block-head {
      padding: 1rem 1.7rem 1.4rem 1rem;
    }
    .preview-your-ad-logo-block {
      width: 3.9rem;
      height: 3.9rem;
      margin-right: 0.8rem;
    }
    .preview-your-ad-menu {
      font-size: 1.5rem;
    }
    .preview-your-ad-user-name {
      font-size: 1.3rem;
      line-height: 1.7rem;
    }
    .preview-your-ad-sponsored-text {
      font-size: 1.2rem;
      line-height: 1.4rem;
      > i {
        font-size: 1rem;
      }
    }
    .preview-your-ad-slider-row {
      border-left: 1rem solid transparent;
      border-right: 1.1rem solid transparent;
      padding-bottom: 3.2rem;
    }
    .preview-your-ad-block-info {
      height: 3.3rem;
      font-size: 1.4rem;
      line-height: 1.7rem;
      letter-spacing: 0.21px;
      padding: 0 1.6rem 0 1rem;
      margin-bottom: 1.3rem;
    }
    .preview-your-ad-card-head {
      height: 7.5rem;
      padding: 0.8rem 1rem 2.8rem 1.1rem;
    }
    .preview-your-ad-card-user {
      font-size: 1.3rem;
      line-height: 1.5rem;
    }
    .preview-your-ad-card-user {
      font-size: 1.3rem;
      line-height: 1.5rem;
    }
    .preview-your-ad-card-user-info {
      font-size: 0.9rem;
      line-height: 1rem;
    }
    .preview-your-ad-card-head-logo {
      width: 3.6rem;
      height: 3.6rem;
      margin-left: 0.4rem;
    }
    .preview-your-ad-card-bottom {
      height: 7.3rem;
    }
    .preview-your-ad-card-bottom-column {
      > p {
        font-size: 1.3rem;
        line-height: 1.7rem;
        letter-spacing: 0.26px;
        max-height: 3.6rem;
      }
      > span {
        font-size: 1.3rem;
        line-height: 1.5rem;
      }
    }
    .slider-arrow {
      width: 2.7rem;
      height: 2.8rem;
      box-shadow: 0 5px 15px #0000001f;
      font-size: 1rem;
      bottom: 5.2rem;
      &.right {
        right: 0.8rem;
      }
      &.left {
        left: 0.8rem;
      }
    }
    .preview-your-ad-card-button {
      height: 3.4rem;
      min-width: 8.6rem;
      font-size: 1.2rem;
      line-height: 1.4rem;
      letter-spacing: 0;
    }
    .preview-your-ad-block-bottom-inner {
      padding: 0.9rem 0 1.6rem 0;
      justify-content: space-around;
    }
    .preview-your-ad-block-bottom-column {
      flex: 0 0 auto;
      > span {
        font-size: 1.2rem;
        line-height: 1.4rem;
      }
      &:last-child {
        transform: translate(6px, 0);
      }
    }
    // carusel AD END
    // single image START
    .preview-your-ad-block {
      &.single-image {
        width: 28.1rem;
        .preview-your-ad-card {
          height: 35.1rem;
          &.has-header {
            .preview-your-ad-card-content {
              border-left: 1.4rem solid transparent;
              border-right: 1.5rem solid transparent;
            }
          }
        }
        .preview-your-ad-slider-row {
          padding-bottom: 1.2rem;
        }
        .preview-your-ad-block-head {
          padding: 0.9rem 0.9rem 1.1rem 0.9rem;
          align-items: flex-start;
        }
        .preview-your-ad-menu {
          padding-top: 0.4rem;
        }
        .preview-your-ad-logo-block {
          width: 3.5rem;
          height: 3.5rem;
          margin-right: 0.7rem;
        }
        .preview-your-ad-user-name {
          font-size: 1.1rem;
          line-height: 1.2rem;
          letter-spacing: 0.17px;
          margin-bottom: 0.4rem;
        }
        .preview-your-ad-sponsored-text {
          font-size: 1rem;
          line-height: 1.1rem;
          letter-spacing: 0.15px;
          > i {
            font-size: 0.9rem;
            padding-left: 0.8rem;
            &:after {
              width: 0.1rem;
              height: 0.1rem;
              left: -0.3rem;
            }
          }
        }
        .preview-your-ad-block-info {
          font-size: 1.2rem;
          line-height: 1.5rem;
          letter-spacing: 0.12px;
          margin-bottom: 0.9rem;
          height: 2.9rem;
        }
        .preview-your-ad-card-head {
          height: 8.9rem;
          padding: 0.9rem 1.5rem 4.2rem 1.4rem;
        }
        .preview-your-ad-card-user {
          font-size: 1.5rem;
          line-height: 1.7rem;
          letter-spacing: 0.45px;
          margin-bottom: 0.5rem;
        }
        .preview-your-ad-card-user-info {
          font-size: 1.1rem;
          line-height: 1.2rem;
          letter-spacing: -0.11px;
        }
        .preview-your-ad-card-head-logo {
          width: 4.1rem;
          height: 4.1rem;
        }
        .preview-your-ad-card-img {
          height: calc(100% + 2.8rem);
          transform: translate(0, -2.6rem);
        }
        .preview-your-ad-card-bottom-column {
          > span {
            font-size: 1rem;
            line-height: 1.1rem;
            margin-bottom: 0.4rem;
          }
          > p {
            font-size: 1.3rem;
            line-height: 1.6rem;
            max-height: 3.2rem;
          }
        }
        .preview-your-ad-block-bottom {
          padding: 0 0.9rem;
        }
        .preview-your-ad-block-bottom-inner {
          padding: 0.7rem 0 1rem 0;
        }
        .preview-your-ad-card-bottom {
          padding: 1.2rem 1.1rem;
        }
        .preview-your-ad-block-bottom-column {
          > span {
            font-size: 1rem;
            line-height: 1.1rem;
            letter-spacing: 0.25px;
          }
          > i {
            margin-right: 0.5rem;
          }
          &:last-child {
            transform: initial;
          }
        }
        .preview-your-ad-card-button {
          height: 3rem;
          font-size: 1.2rem;
          line-height: 1.4rem;
        }
      }
    }
    // single image END
    // instagram START
    .instagram-your-ad-block {
      width: 32.6rem;
      height: 49rem;
      .preview-your-ad-card {
        width: 100%;
        height: 32.7rem;
        .preview-your-ad-card-content {
          border-left: 1.3rem solid transparent;
          border-right: 1.4rem solid transparent;
        }
        .preview-your-ad-card-img {
          // TO DO need delete in refactor time, now need maybe its has negative affect in another styles
          // height: calc(100% + 3rem);
          transform: translate(0, -3rem);
        }
      }
      .preview-your-ad-card-head {
        height: 10.4rem;
        padding: 1.2rem 1.4rem 4.3rem 1.3rem;
      }
      .preview-your-ad-card-user {
        font-size: 1.7rem;
        line-height: 1.9rem;
        margin-bottom: 0.6rem;
      }
      .preview-your-ad-card-user-info {
        font-size: 1.1rem;
        line-height: 1.2rem;
        letter-spacing: 0.11px;
      }
      .preview-your-ad-card-head-logo {
        width: 4.9rem;
        height: 4.9rem;
      }
    }
    .instagram-your-ad-head {
      min-height: 4.1rem;
      padding: 0.7rem 1.1rem 0.5rem 1rem;
    }
    .instagram-your-ad-logo {
      height: 2.9rem;
      width: 2.9rem;
      margin-right: 0.7rem;
    }
    .instagram-your-ad-head-texts {
      > p {
        font-size: 1.1rem;
        line-height: 1.2rem;
      }
      > span {
        font-size: 1rem;
        line-height: 1.1rem;
        letter-spacing: -0.26px;
      }
    }
    .instagram-shop-now {
      margin-bottom: 0.9rem;
      padding: 0 1rem;
    }
    .instagram-shop-now-inner {
      padding: 1.2rem 0;
      > span {
        font-size: 1.2rem;
        line-height: 1.4rem;
      }
      > i {
        font-size: 1.2rem;
      }
    }
    .instagram-your-ad-icons-column {
      grid-gap: 1.5rem;
    }
    .instagram-your-ad-icon {
      font-size: 2.1rem;
    }
    .instagram-your-ad-icons {
      > i {
        font-size: 1.8rem;
      }
    }
    .instagram-your-ad-bottom {
      padding: 0 1rem 1.4rem 1rem;
    }
    // instagram END
    // story START
    .story-insta-your-ad-block {
      width: 27.5rem;
      .preview-your-ad-card {
        height: 26.6rem !important;
        &.has-header {
          .preview-your-ad-card-content {
            border-left: 1.2rem solid transparent;
            border-right: 1.1rem solid transparent;
          }
        }
      }
    }
    // story END
  }
  // preview your ad popup ENd
  .pie-chart-info-title {
    font-size: 4rem;
    line-height: 5.1rem;
  }
  .pie-chart-info-subtitle {
    font-size: 1.3rem;
    line-height: 1.6rem;
    color: rgba(var(--background), 0.72);
  }
  // for pixel perfect in 1440px START dizayni exac xndirneri
  // patcharova senc grvum align exac chen
  // teqster@ avelnord probelner kan teqsteric
  // heto bareri mijev heravorutynne@ word spacingner@
  // tex tex tarber en, nuyn componentner@ amen tex tarber
  // a chap@ 1 pixel i shexumov kam avel. amen ejum koxqeric
  // padingner@ tarber en 90, 86, 88, 87 pixelener u nmanatip liq@ xndirner,
  // dra hamar senc em grum vor berenq dizaynin hamapatasxan
  .slider-arrow {
    bottom: 6rem;
  }
  .presentation-info {
    transform: translate(0.2rem, -0.2rem);
  }
  .step-info-title {
    transform: translate(-0.5rem, 0);
  }
  .step-info-row-content-inner {
    transform: translate(-0.6rem, 0);
    letter-spacing: 0.05rem;
  }
  .next-step-button-block {
    transform: translate(-0.4rem, 0.1rem);
  }
  .select-your-goal-info {
    transform: translate(0, -0.1rem);
  }
  .steps-count-column-inner {
    transform: translate(0.5rem, 0.1rem);
  }
  .copyright-info {
    bottom: 3.9rem;
  }
  .choose-your-media-step-title {
    transform: translate(-0.1rem, 0);
    margin-bottom: 0.6rem;
  }
  .choose-your-media-step {
    padding-top: 3.6rem;
    padding-right: 5.2rem;
  }
  .social-site-column {
    transform: translate(-0.3rem, -0.1rem);
  }
  .social-site-c-plus {
    transform: translate(0.4rem, -0.1rem);
  }
  .social-site-c-inner-column {
    &:nth-child(3) {
      transform: translate(0.3rem, -0.1rem);
    }
  }
  .review-copy-social-row {
    & + .review-copy-input-row {
      margin-bottom: 1.9rem;
    }
  }
  .review-copy-input {
    margin-bottom: 0.5rem;
    padding: 0 0 1rem 0;
  }
  .review-copy-step {
    padding-right: 5.4rem;
  }
  .review-copy-social-row-name {
    padding-top: 0.7rem;
  }
  .review-copy-social-row-column {
    &:last-child {
      padding-left: 0.4rem;
    }
  }
  .choose-listing-content {
    .container {
      max-width: 126.8rem;
    }
    .next-step-row-inner {
      align-items: center;
    }
    .steps-count-column-inner {
      transform: translate(0.1rem, -0.1rem);
    }
  }
  .choose-listing-content-tabs-column-tab {
    padding-bottom: 1.1rem;
  }
  .choose-listing-selected-images-row {
    margin-bottom: 1.8rem;
  }
  .preview-your-ad {
    padding-top: 3.8rem;
    padding-left: 4.2rem;
  }
  .preview-your-ad-content {
    .preview-your-ad-block {
      &.single-image {
        .preview-your-ad-sponsored-text {
          transform: translate(0, 3px);
        }
      }
    }
    .steps-count-column-inner {
      transform: translate(0.7rem, 0rem);
    }
  }
  .preview-your-ad-content-column {
    .preview-your-ad-swipe-block {
      display: flex;
      justify-content: center;
    }
  }
  .preview-your-ad-popup {
    .preview-your-ad-swipe-block {
      display: block;
    }
  }
  .preview-your-ad-info-text-hidden {
    transform: translate(0, 1rem);
  }
  .preview-your-ad-content-column-row {
    &:last-child {
      transform: translate(0, 0.5rem);
    }
  }
  .select-paid-media-wrapper {
    .container {
      max-width: 126.8rem;
    }
    .step-info-row {
      padding-left: 3.6rem;
    }
    .step-info-title {
      transform: translate(3px, 0);
    }
    .select-paid-media-container {
      padding-left: 4.5rem;
    }
    .next-step-button-block {
      transform: translate(0, 0.1rem);
    }
  }
  .select-paid-media-container {
    padding-top: 4rem;
    padding-left: 4rem;
  }
  .select-paid-package-name-row {
    transform: translate(0.1rem, -0.3rem);
  }
  .select-paid-package-day {
    transform: translate(2px, 0);
  }
  .select-paid-package-reached {
    transform: translate(2px, 0);
  }
  .payment-content {
    margin: 0 auto;
    padding-top: 10.02rem;
  }
  .payment-input {
    border-width: 0.2rem;
  }
  .analytics-page {
    .analytics-page-tabs-datepicker-row {
      padding: 2.4rem 0 2.3rem 0;
    }
  }
  .analytics-d-head-title {
    transform: translate(-0.4rem, 0);
  }
  .analytics-d-table-holder-tabs {
    gap: 0;
  }
  .analytics-d-table-sort {
    right: -1.4rem;
  }
  .analytics-d-table-status-circle {
    width: 0.8rem;
    height: 0.8rem;
  }
  .area {
    padding-top: 0;
    height: 100%;
    //div {
    //  height: 100% !important;
    //  width: 100% !important;
    //  transform: scale(1.3);
    //}
    //svg {
    //  height: 100% !important;
    //  width: 100% !important;
    //}
  }
  .analytics-page-tab {
    padding-right: 0.002rem;
  }
  .share-with-client-popup-shadow-close {
    padding: 2rem 4rem;
  }
  .listing-traffic-page-card {
    &:first-child {
      .traffic-card-body {
        padding: 2.4rem 4.2rem 1rem 4.3rem;
      }
    }
    &:nth-child(2) {
      .traffic-card-body {
        padding-top: 1.4rem;
        padding-bottom: 2rem;
      }
    }
  }
  // refactor i jamanak chka vor poxem strukturan popupi
  .share-with-client-popup {
    height: calc(100vh - 40px);
  }
  // refactor i jamanak chka vor poxem strukturan popupi

  .your-ad-details-head-info-column {
    &:not(:last-child) {
      margin-right: 5.6rem;
    }
    &:nth-child(2) {
      transform: translate(2px, 0);
    }
  }
  .your-ad-details-head-info-title {
    margin-bottom: 0;
    transform: translate(-1px, -4px);
    letter-spacing: 0.55px;
  }
  .reach-and-clicks-text {
    transform: translate(0, 0.3rem);
  }
  // WARNING its case work only with case in line 1672, if change line 1672 need change itess too
  .pdf-total-listing .pie-chart {
    transform: unset;
    width: 25rem;
    height: 26rem;
  }

  // WARNING if change this case need change 1667 line for pdf
  .pie-chart {
    transform: translate(-22px, -13px);
    margin-right: 0;
    svg {
      width: 252px !important;
      height: 260px !important;
    }
  }
  .your-listing-analytics-address {
    transform: translate(0, -0.2rem);
  }
  .back-to-all-ads-block {
    transform: translate(-1px, -4px);
  }
  .map-chart {
    transform: scale(1.1);
    transform-origin: left top;
  }
  .map-chart-table {
    transform: translate(0, 2px);
  }
  .map-chart-table-row {
    height: 4.9rem;
  }
  .map-chart-table-cs {
    &:first-child {
      width: 44%;
    }
    &:last-child {
      .map-chart-table-column {
        &:nth-child(1) {
          flex: 0 0 auto;
          width: 28%;
        }
        &:nth-child(2) {
          flex: 0 0 auto;
          width: 38%;
        }
      }
    }
  }
  .empty-card,
  .reports-content .empty-card {
    min-height: 250px;
  }
  .staked-bar-chart {
    padding-top: 0;
    padding-bottom: 3.2rem;
    padding-left: 3.2rem;
    height: 274px;
  }
  // pay card START
  .payment-content {
    max-width: 29.6rem;
    .steps-custom-radio-button {
      margin-right: 0.8rem;
    }
  }
  .payment-head {
    margin-bottom: 3.2rem;
  }
  .payment-content-inner {
    //margin-bottom: 2rem;
    padding-bottom: 2rem;
  }
  .payment-input-row-column {
    padding-top: 2.5rem;
  }
  .insufficient-founds {
    > h3 {
      font-size: 1.4rem;
      line-height: 2.2rem;
      margin-bottom: 1.2rem;
    }
    > p {
      font-size: 1.3rem;
      line-height: 2.2rem;
    }
  }
  .payment-input {
    font-size: 1.4rem;
    line-height: 1.8rem;
  }
  .payment-input-row {
    grid-gap: 2.8rem;
    &:not(:last-child) {
      margin-bottom: 2.4rem;
    }
    &.error {
      .payment-input-r-title {
        font-size: 1.1rem;
      }
    }
    &:nth-child(2) {
      transform: translate(0, 1px);
    }
  }
  .payment-bottom {
    transform: translate(0, 1px);
  }
  .payment-inputs {
    margin-top: 2.4rem;
  }
  .payment-input-r-title {
    margin-bottom: 1.2rem;
  }
  .payment-title {
    font-size: 2.4rem;
    line-height: 3rem;
  }
  .payment-label-text {
    font-size: 1.4rem;
    line-height: 1.8rem;
  }
  .payment-bottom-inner {
    padding-bottom: 2.4rem;
  }
  .insufficient-open {
    .payment-bottom-inner {
      padding-bottom: 1.4rem;
    }
  }
  .payment-b-row {
    font-size: 1.1rem;
    &.bold {
      font-size: 1.3rem;
      margin-top: 0.8rem;
    }
  }
  .insufficient-founds {
    > p {
      max-width: 23.9rem;
    }
  }
  // pay card END

  // for pixel perfect in 1440px END
}

@media screen and (min-width: 1440px) and (max-width: 1919px) {
  .copyright-info {
    right: 2.3rem;
  }
}
@media screen and (max-width: 1440px) {
  //listing page START
  .container {
    max-width: 126rem;
  }
  .presentation-view-all-button {
    min-width: 18.2rem;
    height: 4.2rem;
    font-size: 1.3rem;
    line-height: 1.7rem;
  }
  .listing-page-content {
    padding: 2rem 3rem 0 3rem;
  }
  .listing-page-search-row {
    padding-bottom: 1.2rem;
  }
  .listing-page-search-icon {
    font-size: 1.2rem;
    margin-right: 1.6rem;
  }
  .listing-page-search {
    font-size: 1.3rem;
  }
  .sort-name {
    font-size: 1.3rem;
    line-height: 1.7rem;
  }
  .sort-icon {
    font-size: 0.6rem;
    margin-left: 0.8rem;
  }
  .listing-page-home-cards {
    grid-template-columns: repeat(auto-fill, minmax(25.6rem, 1fr));
  }
  .list-card-img-row {
    height: 19.2rem;
  }
  .list-card-info-row {
    padding: 1.2rem 1.4rem 2rem 1.4rem;
  }
  .home-address {
    font-size: 1.4rem;
    line-height: 1.8rem;
    margin-bottom: 0.6rem;
  }
  .list-price-id-row {
    grid-column-gap: 1.2rem;
    margin-bottom: 1.1rem;
  }
  .list-card-buttons {
    grid-template-columns: 10rem 1fr;
    grid-column-gap: 1.1rem;
  }
  .list-price {
    font-size: 1.8rem;
    line-height: 2.3rem;
  }
  .list-id {
    font-size: 1.3rem;
    line-height: 2rem;
  }
  .copyright-text-info {
    font-size: 1rem;
  }
  .footer {
    padding: 4.8rem 5.5rem 2.8rem 4.8rem;
  }
  //listing page END
  .client-finder-notification {
    font-size: 1.3rem;
    line-height: 1.7rem;
    > i {
      font-size: 1.4rem;
    }
  }
  .review-copy-social-row {
    margin-bottom: 2.5rem;
  }
  .review-copy-radio-buttons-column {
    &:not(:last-child) {
      margin-right: 2.6rem;
    }
  }
  .pie-list {
    max-height: 23rem;
  }
}
@media screen and (min-width: 1440px) {
  //Blank Popup Start
  .blank-popup-content {
    max-width: 508px;
    min-height: auto;
  }
  //Blank Popup End
}
@media screen and (max-width: 1441px) and (min-width: 1320px) {
  // for pixel perfect in 1440px START
  .analytics-d-table-row.total {
    justify-content: space-between;
  }
  .analytics-dashboard {
    .analytics-d-table-row {
      &:nth-child(3) {
        transform: translate(0, 1px);
        & ~ div {
          transform: translate(0, 2px);
        }
      }
      &:nth-child(5) ~ div {
        transform: translate(0, 4px);
      }
    }
    .swipe-cnt {
      .analytics-d-table-row {
        padding-left: 1.8rem;
        &.head {
          .analytics-d-table-row-col {
            &:nth-child(5) {
              text-align: right;
            }
          }
        }
        &:not(.head) {
          .analytics-d-table-row-col {
            &:nth-child(5) {
              padding-left: 2.5rem;
            }
          }
        }
      }
      .analytics-d-table-row-col {
        flex: 0 0 auto;
        width: 100%;
        &:first-child {
          max-width: 13.2rem;
        }
        &:nth-child(2) {
          max-width: 9.8rem;
        }
        &:nth-child(3) {
          max-width: 10rem;
        }
        &:nth-child(4) {
          max-width: 10rem;
        }
        &:nth-child(5) {
          max-width: 11.9rem;
          padding-right: 1.7rem;
        }
        &:nth-child(6) {
          max-width: 10rem;
        }
      }
    }
  }
  .analytics {
    .user-name {
      transform: translate(0, 2px);
    }
  }
  .analytics-page {
    .cancel-the-ad-button {
      transform: translate(0, 2px);
    }
    .analytics-d-table-row {
      &:nth-child(2) ~ div {
        transform: translate(0, 1px);
      }
      &:nth-child(5) ~ div {
        transform: translate(0, 4px);
      }
    }
    .swipe-cnt {
      .analytics-d-table-row {
        justify-content: space-between;
        padding-left: 1.2rem;
      }
      .analytics-d-table-row-col {
        flex: 0 0 auto;
        width: 100%;
        &:first-child {
          max-width: 10.1rem;
        }
        &:nth-child(2) {
          max-width: 10.1rem;
        }
        &:nth-child(3) {
          max-width: 9.7rem;
        }
        &:nth-child(4) {
          max-width: 9rem;
        }
        &:nth-child(5) {
          max-width: 7.6rem;
          padding-left: 1.2rem;
        }
        &:nth-child(6) {
          max-width: 8.8rem;
          text-align: center;
        }
        &:nth-child(7) {
          max-width: 10rem;
        }
      }
    }
  }

  // for pixel perfect in 1440px END
}
@media screen and (max-width: 1240px) {
  .traffic-row {
    grid-template-columns: 1fr;
  }
  .area-legends {
    display: flex;
  }
  // main hiddens START
  .lg-hidden {
    display: none;
  }
  // main hiddens END
  //analytics START
  .analytics-page {
    .footer {
      padding: 4rem 0 2.6rem 0;
    }
  }
  // this part css hide  year in datepicker input
  // .datepicker-text {
  //   span {
  //     display: inline-block;
  //   }
  // }

  // main hiddens START
  .lg-hidden {
    display: none;
  }
  // main hiddens END

  // your ad details START
  .your-ad-details-info-column {
    &:last-child {
      .your-ad-details-min-info-content {
        left: auto;
        transform: none;
        right: -2.6rem;

        &:before {
          left: auto;
          margin: 0;
          right: 2.5rem;
        }
      }
    }
  }
  // your ad details END
  .your-listing-analytics-name {
    font-size: 3.5rem;
    line-height: 3.5rem;
    margin-bottom: 0.6rem;
  }
  .your-listing-analytics-address {
    font-size: 1.6rem;
    line-height: 2rem;
  }
  .analytics-page-tabs-datepicker-row {
    padding: 3rem 0 2.4rem 0;
  }
  .analytics-page-datepicker-column {
    width: 100%;
    transform: translate3d(0, 0, 0);
    position: relative;
    z-index: 150;
  }
  .analytics-page-table-head {
    padding: 4rem 2.4rem 3.4rem 2.4rem;
  }
  .analytics-page-table {
    padding: 0;
    margin-bottom: 8rem;
  }
  .analytics-page-table-column {
    &.platforms {
      width: 39%;

      .analytics-page-table-dropdown-content {
        width: 26rem;
      }
    }

    &.goal {
      width: 16%;
    }

    //&:not(:last-child) {
    //  margin-right: 2rem;
    //}

    &.see-details {
      //width: 8rem;
      width: 10rem;
    }

    &.cancel-ad {
      width: 2rem;
    }

    &.start,
    &.end {
      display: none;
    }
  }
  .analytics-page-table-dropdown-content {
    left: -1rem;
  }
  .analytics-page-table-column-text {
    font-size: 1.4rem;
    line-height: 1.8rem;
  }
  .see-details-button {
    > span {
      display: none;
    }
  }
  .cancel-the-ad-popup-text {
    max-width: 37rem;
  }
  //analytics END

  //your ad details START
  .your-ad-details-head-inner {
    display: block;
  }
  .your-ad-details-head-info-column {
    &:first-child {
      min-width: 24.4rem;
    }

    &:not(:last-child) {
      margin-right: 2rem;
    }
  }

  .your-ad-details-head-button {
    width: 24rem;
    margin-right: 2rem;
  }
  // .your-ad-details-head-link {
  //   position: absolute;
  //   left: 75.6%;
  //   bottom: -5rem;
  // }
  .your-ad-details-head-inner-column {
    &:first-child {
      margin-bottom: 2rem;
    }
  }
  .reports-content-table-row {
    padding: 0 2.4rem;
  }
  .reports-head {
    padding: 3.1rem 2.4rem;
  }
  .reach-and-clicks-title {
    margin-bottom: 0.4rem;
    font-size: 1.8rem;
    line-height: 2.3rem;
  }
  .staked-bar-chart {
    padding: 0 2.4rem 2rem;
  }
  .reports-content-table-total {
    padding: 2rem 2.4rem 3.7rem 2.4rem;
  }
  .map-chart-content {
    display: block;
    padding-right: 0;
  }
  .map-chart-content-column {
    &.chart-column {
      width: 100%;
      height: 42.6rem;
    }
    &.table-column {
      padding-right: 0;
      overflow-y: auto;
      max-height: 388px;
    }
  }
  .map-chart-table {
    border-right: 0;
    padding-right: 0;
  }
  .reports-row {
    grid-template-columns: 12fr;
    grid-auto-flow: row;
  }
  .reports-column {
    max-height: 44rem;
  }
  .map-chart-table-cs {
    &:first-child {
      .map-chart-table-row {
        padding-left: 2.4rem;
      }
    }
    &:last-child {
      .map-chart-table-row {
        padding-right: 2.4rem;
      }
    }
  }
  .map-chart-table-row {
    &.head {
      background-color: #fcfcfc;
    }
  }
  .your-ad-details-info-column {
    &:nth-child(even):not(:last-child) {
      .your-ad-details-min-info-content {
        left: auto;
        transform: none;
        right: -2.6rem;

        &:before {
          left: auto;
          margin: 0;
          right: 2.5rem;
        }
      }
    }
  }
  .reports-head-typ {
    grid-auto-flow: column;
    grid-gap: 3.2rem;
  }
  // your ad details END

  // listing traffic start
  .container {
    .traffic-card {
      &-body {
        height: auto;
      }
    }

    .pie-list {
      column-count: unset;
      column-gap: unset;
      column-width: unset;
      width: 100%;
      .list-item {
        height: 40px;
      }
    }

    .pie-chart {
      min-width: unset;
    }
  }
  // listing traffic END

  // Analitics dashboard start
  .no-result {
    padding-top: 7rem;
  }
  // Analitics dashboard END
}
//Tablet START
@media (min-width: 768px) and (max-width: 980px) {
  .your-listing-analytics-row-inner {
    &.has-rm-manager {
      .analytics-active-listing-button.tablet-button {
        width: 182px;
        min-width: 182px;
        height: 42px;
        font-size: 13px;
        line-height: 20px;
        margin-right: 0;
      }
      .analytics-download-button-icon {
        font-size: 42px;
      }
    }
  }
}
@media screen and (max-width: 979px) {
  .analytics-page-tabs-datepicker-row {
    display: block;
    padding: 3rem 0 2.4rem 0;
  }
  .analytics-page-datepicker-column {
    margin-top: 3.2rem;
  }

  .submitted-price-icon {
      width: 1.6rem;
      height: 1.6rem;
  }
  .your-ad-details-point {
    font-size: 4.2rem;
    line-height: 5.3rem;
  }
}

@media screen and (max-width: 980px) {
  .choose-listing-s-i-column-left {
    margin-right: 1.6rem;
  }
  .your-listing-analytics-row-inner {
    &.has-rm-manager {
      display: flex;
      align-items: center;
    }
  }
  // start Pay page
  .payment-content {
    margin-top: 174px;
  }
  .marketing-budget:checked ~ .payment-inputs {
    max-height: 3rem;
  }
  .payment-label.has-margin {
    margin-left: 3.5rem;
  }

  .payment-inputs {
    margin-top: 4.3rem;
  }
  .payment-popup-close {
    right: 1.4rem;
    top: 3.6rem;
  }
  .payment-bottom-inner {
    padding-top: 0.2rem;
  }
  .payment-b-row:not(:last-child) {
    margin-bottom: 0.7rem;
  }
  // end Pay page

  // .main-wrapper {
  //   // its need disabled for modal overlow scrolling body
  //   // overflow: initial;
  // }
  // main hiddens START
  .lg-hidden {
    display: initial;
  }
  .md-hidden {
    display: none;
  }
  // main hiddens END
  ::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
  // steps START
  .step-cnt {
    padding: 3rem 4.8rem 3.2rem 4.8rem;
  }
  .step-arrow {
    &:hover {
      color: rgba(#ffffff, 0.5);
      border: 0.1rem solid rgba(#ffffff, 0.3);
      background: none;
    }
  }
  // steps END
  // listing page START
  //.main-wrapper{
  //  padding-top: 6.8rem;
  //}
  .header {
    height: 6.8rem;
  }
  .footer {
    padding: 4.8rem 2.4rem 3.6rem 4.8rem;
  }

  .presentation-row-inner {
    flex-direction: column;
    text-align: center;
  }
  .presentation-row {
    padding: 3.2rem;
  }
  .presentation-row-texts {
    padding-left: 0;
  }
  .presentation-user-name-column {
    position: relative;
    padding-left: 4.8rem;
  }
  .presentation-row-logo {
    width: 3.6rem;
    height: 3.6rem;
  }
  .presentation-user-name {
    font-size: 4.4rem;
    line-height: 5.5rem;
    margin-bottom: 0;
  }
  .presentation-info {
    margin-top: 0.3rem;
    margin-bottom: 2rem;
    &.marginB-zero {
      margin-bottom: 0;
    }
  }
  .list-price-id-title {
    font-size: 1rem;
  }
  .list-id {
    font-size: 1.4rem;
  }
  .list-card-buttons {
    .button {
      font-size: 1.5rem;
      padding: 0 0.5rem;
    }
  }
  .list-card-info-row {
    padding: 1.6rem 2.2rem;
  }
  .home-address {
    font-size: 1.3rem;
  }
  .list-price {
    font-size: 2rem;
  }
  .listing-page-home-cards {
    grid-column-gap: 3.2rem;
    grid-row-gap: 2.8rem;
    grid-template-columns: repeat(auto-fill, minmax(32rem, 1fr));
  }
  .list-card-img-row {
    height: 23.2rem;
  }
  //.list-card-buttons {
  //  grid-template-columns: 10.4rem 1fr;
  //}
  // listing page END
  // steps count START
  .social-popup {
    max-width: 37.6rem;
  }
  // steps count END
  // review Copy START
  .review-copy-inputs-row {
    width: 100%;
    grid-column-gap: 3.2rem;
    grid-template-columns: 1fr 1fr;
  }
  .review-copy-inputs-column {
    width: 100%;
  }
  .review-copy-radio-buttons-row {
    max-width: 35.2rem;
  }
  .review-copy-radio-buttons-column {
    &:not(:last-child) {
      margin-bottom: 1.6rem;
    }
  }
  // review Copy END
  // choose listing images step START
  .choose-listing-images-view-step {
    .steps-bottom {
      padding: 2.4rem 0;
      min-height: 13.6rem;
    }
  }
  .steps-bottom {
    transition: transform 240ms ease-in-out;

    &.active {
      transform: translateY(4.4rem);

      .next-step-left-column {
        transform: initial;
      }
    }
  }
  .choose-listing-head-texts {
    transition: opacity 240ms;
  }
  .choose-listing-selected-images {
    flex: 1;
  }
  .choose-listing-content-row {
    padding: 3.2rem 0 3.6rem 0;
  }
  .choose-listing-head-column-right-content {
    width: 100%;
  }
  .choose-listing-head-choose-column {
    flex: 1;
    gap: 1.2rem;
    min-width: 34.4rem;
  }
  .choose-listing-selected-images-row {
    width: 100%;
  }
  .choose-listing-s-i-column-left {
    flex: 0 0 auto;
  }
  .choose-listing-head-images {
    margin-right: 3rem;
  }
  .choose-listing-s-i-column-right {
    flex: 1;
  }
  .choose-listing-content-inner {
    grid-column-gap: 3.2rem;
    grid-row-gap: 3.2rem;
    grid-template-columns: repeat(auto-fill, minmax(32rem, 1fr));
  }
  .choose-listing-card {
    height: 24rem;
  }
  // choose listing images step END
  // select paid media package START
  .select-paid-media-title {
    margin-bottom: 3.8rem;
  }
  .select-paid-media-cards {
    grid-template-columns: repeat(2, 27.6rem);
  }
  // .select-paid-media-card {
  //   padding: 2.4rem 3.2rem;
  // }
  .select-paid-package-name-row {
    margin-bottom: 1.2rem;
  }
  .select-paid-package-name {
    font-size: 2rem;
    line-height: 2.5rem;
  }
  .select-paid-package-price-row {
    margin-bottom: 1.6rem;
  }
  .select-paid-package-price {
    font-size: 4.8rem;
    line-height: 6.1rem;
    margin-bottom: 0;
  }
  .select-paid-package-reached {
    font-size: 1.4rem;
    line-height: 2.4rem;
  }
  // select paid media package END
  // social popup START
  .social-popup-content {
    padding: 0 4.4rem 1.4rem 4.4rem;
  }
  // social popup END
  // Confirmation START
  .submitted-block,
  .pdf-download {
    max-width: 32rem;
  }
  .submitted-block {
    padding: 4rem 2.4rem 3.2rem 2.4rem;
  }
  // Confirmation END
  // preview your ad START

  .preview-your-ad-content {
    grid-gap: 5.6rem;
    padding-top: 13.1rem;
  }
  .preview-your-ad-content-column-row-cnt {
    .review-copy-radio-form {
      max-width: 100%;
    }
  }
  .preview-your-ad-content-column-row {
    .steps-count-column {
      display: none;
    }
  }
  .preview-your-ad-content-column-button {
    gap: 1.6rem;
    flex-direction: column;
    width: 19.2rem;

    .back-button {
      width: 100%;
      max-width: unset;
    }
  }
  .preview-your-ad-info-text-hidden {
    display: inline-block;
    vertical-align: top;
    margin-left: 0.4rem;
    width: auto;
  }
  .preview-social-tabs-container {
    margin-top: 0;
  }
  .preview-your-ad-info-text {
    position: absolute;
    left: 0;
    top: 0;
    max-width: 100%;
    text-align: initial;
  }
  .preview-social-tabs {
    .analytics-page-tabs-column {
      display: block;
    }

    .analytics-page-tab {
      padding-left: 3.2rem;

      &:before {
        display: block;
        background-color: rgba(var(--background), 1);
        width: 1rem;
        height: 1rem;
        top: 0;
        bottom: 0;
        margin: auto;
        left: 0.5rem;
        border-radius: 50%;
        opacity: 0;
        transition: opacity 240ms ease-in-out;
      }

      &:after {
        content: "";
        display: block;
        position: absolute;
        width: 2rem;
        height: 2rem;
        border-radius: 50%;
        border: 0.1rem solid rgba(var(--background), 1);
        top: 0;
        bottom: 0;
        margin: auto;
        left: 0;
      }

      &.active {
        &:before {
          opacity: 1;
        }
      }
    }
  }
  // preview your ad END
  .your-ad-details-info-row {
    &.full {
      grid-auto-flow: row;
      grid-template-columns: 12fr;
    }
  }
  .steps-count-title {
    font-size: 1.8rem;
  }
  .steps-count-item {
    width: 4.3rem;
  }
  .select-paid-package-day {
    font-size: 1.5rem;
  }
  .preview-your-ad-popup-close {
    right: 1.4rem;
    top: 3.6rem;
  }
  // Analytics START
  .permission-issue-content {
    max-width: 46rem;
  }
  .client-finder {
    &.analytics {
      .user-block {
        padding-right: 4rem;

        &.manager {
          padding-right: 0;
        }
      }
    }
  }
  .analytics-menu {
    display: block;
  }
  .your-listing-analytics-row {
    padding: 2.4rem 2.4rem 1.8rem;
  }
  .your-listing-analytics-row-c-buttons {
    flex-wrap: wrap;
    position: fixed;
    top: 6.8rem;
    right: 0;
    width: 100%;
    z-index: 400;
    opacity: 0;
    transition: opacity 240ms;
    pointer-events: none;
    background-color: #fcfcfc;
    box-shadow: 0 2rem 2rem 0 rgba(#000000, 0.17);
    padding: 2.5rem 2.4rem;
    border-top: 0.1rem solid #f0eff2;
    gap: 1rem;
  }
  .analytics-menu-open-checkbox {
    pointer-events: auto;

    &:checked {
      & ~ .your-listing-analytics-row-c-buttons,
      & ~ .back-to-all-ads-mobile-buttons {
        opacity: 1;
        pointer-events: auto;
      }
    }
  }
  .analytics-active-listing-button {
    &:not(.tablet-button) {
      background-color: rgba(var(--background), 1);
      border-color: rgba(var(--background), 1);
      color: #ffffff;
      margin-right: 0;
      max-width: 100%;

      &:hover {
        background-color: rgba(var(--background), 1);
        color: #ffffff;
      }
    }
    &.tablet-button {
      margin-left: auto;
      display: block;
    }
  }
  .analytics-download-button {
    display: flex;
    align-items: center;
    justify-content: center;
    &.manager {
      display: flex;
      .analytics-download-button-icon {
        color: #ffffff;
      }
    }
    &:hover {
      .analytics-download-button-text {
        color: rgba(var(--background), 1);
      }
    }
  }
  .analytics-download-button-icon {
    color: rgba(var(--background), 1);
    //margin-right: 1.3rem;
  }
  .analytics-download-button-text {
    color: rgba(var(--background), 1);
    font-size: 1.5rem;
    line-height: 1.9rem;
    letter-spacing: 0.3px;
    font-weight: 600;
  }
  // analytics END
  // Your ad details START
  .back-to-all-ads-btn:not(.manager) {
    display: none;
  }
  .details {
    .user-block {
      padding-right: 4rem;
      &.manager {
        padding-right: 0;
      }
    }
  }
  // Your ad details END
  .list-card-buttons {
    grid-template-columns: 6fr 6fr;
  }
  // listing traffic start
  .container {
    .pie-chart {
      width: 268px;
      height: 268px;
    }

    .traffic-card {
      &-head {
        border-bottom: 1px solid #efefef;
        padding: 26px 24px;
      }

      &-head-title {
        font-size: 1.8rem;
        line-height: 2.2rem;
      }

      &-head-subtitle {
        font-size: 1.4rem;
        line-height: 2rem;
      }
    }
  }
  // listing traffic END

  .next-step-button-block {
    gap: 1.6rem;
    min-width: 31.6rem;
  }
  .copyright-info {
    position: static;
  }
}
//Tablet END
// Mobile START
@media screen and (max-width: 767px) {
  .choose-listing-head-choose-column {
    min-width: unset;
  }
  .back-to-all-ads-btn:not(.manager) {
    display: none;
  }
  // main hiddens START
  .lg-hidden {
    display: initial;
  }
  .md-hidden {
    display: initial;
  }
  .xs-hidden {
    display: none;
  }

  .header {
    height: 5.4rem;
  }
  .analytics-page {
    .footer {
      padding: 2rem 0 2.1rem 0;
    }
  }

  .footer {
    padding: 2rem 2.4rem 2.1rem 2.4rem;
  }
  .button {
    //height: 5.1rem;
    font-size: 1.5rem;
    line-height: 1.7rem;
    letter-spacing: 0.3px;
  }
  .user-name-column {
    display: none;
  }
  .avatar-column {
    width: 3.2rem;
    height: 3.2rem;

    .avatar-img.placeholder {
      font-size: 1.6rem;
    }
  }
  .pdf-total-listing {
    .avatar-column {
      .avatar-img.placeholder {
        font-size: 2.4rem;
      }
    }
  }
  .logo-img {
    max-width: 150px;
    max-height: 28px;
  }
  .list-card-img-row {
    height: 21.2rem;
  }
  .presentation-row {
    padding: 1.5rem 2rem 2rem 2rem;
  }
  .presentation-user-name-column {
    padding-left: 3.6rem;
  }
  .presentation-user-name {
    font-size: 2.6rem;
    line-height: 3.4rem;
  }
  .presentation-row-logo {
    width: 2.4rem;
    height: 2.4rem;
  }
  .presentation-info {
    font-size: 1.4rem;
    line-height: 1.8rem;
    margin-bottom: 1.6rem;
  }
  .presentation-view-all-button {
    height: 4.8rem;
    min-width: 22rem;
    &.manager {
      display: none;
    }
  }

  // steps START
  .step-cnt {
    padding: 2rem 2.4rem;
  }
  .select-your-goal-label-head {
    margin-bottom: 1rem;
  }
  .select-your-goal-name {
    font-size: 1.6rem;
    line-height: 2rem;
  }
  .select-your-goal-info {
    font-size: 1.4rem;
    line-height: 2.4rem;
  }
  .social-popup {
    grid-row-gap: 0;
  }
  .social-sites-row {
    grid-gap: 1.2rem;
    grid-auto-flow: row;
    padding-top: 0.8rem;
  }
  .social-site-column {
    padding: 1.8rem 2.6rem 1.5rem 2.6rem;
    min-width: 12.8rem;

    &:nth-child(2) {
      max-width: 12.8rem;
    }
  }
  .social-popup-media-name {
    font-size: 2.4rem;
    line-height: 3rem;
  }
  .social-popup-c-images-row {
    margin-bottom: 2.4rem;
  }
  .social-popup-info {
    font-size: 1.4rem;
    line-height: 2.4rem;
  }
  .social-popup-bottom {
    display: block;
  }
  .social-popup-content {
    padding: 3rem 2rem 0 3.2rem;
  }
  .choose-your-media-step-title {
    font-size: 1.3rem;
    line-height: 2.1rem;
  }
  .social-site-images {
    width: 4rem;
    height: 4rem;
    margin-bottom: 1rem;
  }
  .social-site-name {
    font-size: 1.2rem;
    letter-spacing: 0.84px;
    margin-bottom: 0;
  }
  .social-site-choose {
    font-size: 1.1rem;
  }
  .next-step-row-inner {
    display: block;
  }
  .select-your-goal-label {
    margin-bottom: 1.6rem;
  }
  .step-info-row-content {
    &.step-1 {
      .step-info-title-hide {
        display: inline-block;
      }

      .steps-arrows {
        display: none;
      }
    }
  }
  .next-step-row {
    padding: 0 2.4rem;
  }
  .steps-count-column {
    display: none;
  }
  // .next-step-row-inner {
  //   &.active {
  //     .choose-listing-head-texts {

  //     }
  //   }
  // }
  .next-step-left-column {
    width: 100%;
  }
  .next-step-button-block {
    width: 100%;
    min-width: initial;
  }
  .select-your-goal-label {
    &.last {
      margin-bottom: 0;
    }
  }
  .steps-custom-radio-button {
    margin-right: 0.8rem;
  }
  // steps END

  // review Copy START
  .review-copy-inputs-row {
    display: block;
  }
  .review-copy-input-r-latter-count {
    font-size: 1rem;
  }
  .review-copy-inputs-column {
    &:not(:last-child) {
      margin-bottom: 3.6rem;
    }
  }
  .review-copy-radio-form {
    max-width: 30rem;
  }
  .review-copy-radio-text {
    font-size: 1.2rem;
  }
  .review-copy-social-row {
    margin-bottom: 2rem;
  }
  .review-copy-s-img-block {
    width: 4rem;
    height: 4rem;
  }
  .review-copy-s-plus {
    height: 4rem;
  }
  .review-copy-s-img {
    max-width: 2rem;
    max-height: 2rem;
  }
  .review-copy-input-row {
    margin-bottom: 2.4rem;
  }
  .custom-editable-text {
    font-size: 1.6rem;
    line-height: 2.4rem;
  }
  .review-copy-social-row-name {
    font-size: 0.9rem;
    padding-top: 0.7rem;
  }
  .review-copy-radio-buttons-row {
    max-width: 100%;
  }
  // review Copy END

  // listing page START
  .listing-page-content {
    padding: 1.6rem 1.4rem 0 1.4rem;
  }
  .listing-page-home-cards {
    grid-gap: 2rem;
    grid-template-columns: 1fr;
  }
  .list-card-buttons {
    grid-template-columns: 1fr 1fr;
  }
  .list-card {
    max-width: 32rem;
    width: 100%;
    margin: 0 auto;

    .button {
      height: 5.1rem;
    }
  }
  .home-address {
    font-size: 1.2rem;
    margin-bottom: 0.9rem;
  }
  .list-price {
    font-size: 1.8rem;
  }
  .list-price-id-title {
    font-size: 0.9rem;
  }
  .list-id {
    font-size: 1.2rem;
  }
  .list-card-info-row {
    padding: 1.4rem 2rem;
  }
  .sort-name {
    font-size: 1.4rem;
  }
  .sorting-dropdown {
    min-width: 11rem;
  }
  .sorting-dropdown-head {
    padding: 0 0 0 1.8rem;
    justify-content: flex-end;
  }
  // listing page END
  // steps START
  .step-info-title {
    font-size: 1.2rem;
    line-height: 1.5rem;
    margin-bottom: 0.1rem;
    letter-spacing: 1.2px;
  }
  .step-info-title-hide {
    display: none;
  }
  .step-info-row {
    position: -webkit-sticky;
    position: sticky;
    top: 5.4rem;
    padding: 1.6rem 0.4rem 1.6rem 1.6rem;
    z-index: 200;
  }
  .step-name {
    font-size: 2.3rem;
    line-height: 3.2rem;
  }
  .step-name-hide {
    display: none;
  }
  .step-name-container {
    height: 3.2rem;
  }
  .steps-arrows {
    position: relative;
    top: -10px;
  }
  .step-arrow {
    width: 3rem;
    height: 3rem;
    margin: 0 0.2rem;
    font-size: 1.2rem;
    //border: none;
  }
  // steps END
  // choose listing images START
  .choose-listing-images-view-step {
    .steps-bottom {
      padding: 0;
      min-height: initial;
      height: auto;
    }

    .scrollable-container {
      padding: 0 2rem;
    }

    .choose-listing-content-row {
      padding: 0.8rem 0 0 0;

      .choose-listing-content-tabs-column-tab {
        font-size: 1.4rem;
      }
    }
  }
  .choose-listing-content-inner {
    grid-row-gap: 2rem;
    padding-top: 1.6rem;
    grid-template-columns: 1fr;
  }
  .choose-listing-content-copyright-row {
    padding-bottom: 2.4rem;
  }
  .choose-listing-card {
    height: 21rem;
  }
  .choose-listing-head-no-images {
    font-size: 1rem;
  }
  .choose-listing-selected-images {
    flex: 1;
    padding: 1.6rem 0 1rem 0;
    position: relative;
  }
  .choose-listing-head-info {
    font-size: 1.2rem;
  }
  .choose-listing-head-choose-column {
    // position: absolute;
    // left: 0;
    // bottom: 0;
    margin-top: 1.6rem;
    width: 100%;
    min-width: unset;
  }
  .choose-listing-s-i-column-left {
    justify-content: center;
    width: 100%;
    margin-right: 0;
  }
  .choose-listing-head-images {
    margin: 0;
  }
  .choose-listing-selected-images-row {
    margin-bottom: 1rem;
    flex-direction: column-reverse;
    align-items: baseline;
  }
  // choose listing images END
  // select paid media package START
  .select-paid-media-cards {
    grid-gap: 2rem;
    grid-template-columns: 1fr;
  }
  .select-paid-media-title {
    display: none;
  }
  .select-paid-media-cards {
    grid-template-columns: repeat(auto-fill, minmax(12.8rem, 1fr));
    grid-gap: 1.6rem;
  }
  .select-paid-media-card {
    padding: 1.3rem 1rem 1.3rem 1.2rem;
  }
  .select-paid-package-name-row {
    padding-right: 0;
  }
  .select-paid-package-name {
    font-size: 1.3rem;
    line-height: 2rem;
  }
  .select-paid-media-car-icon {
    font-size: 1.5rem;
    right: 0;
  }
  .select-paid-package-day {
    font-size: 1.1rem;
    line-height: 1.4rem;
  }
  .select-paid-package-price {
    font-size: 2.4rem;
    line-height: 3rem;
    margin-bottom: 0.4rem;
  }
  .select-paid-package-reached {
    font-size: 1.1rem;
    line-height: 1.4rem;
  }
  // select paid media package END
  // payment options START
  .payment-options-container {
    padding: 0;
  }
  .payment-content {
    display: grid;
    grid-template-rows: 11fr auto;
    height: 100%;
    min-height: 40rem;
    margin-top: auto;
    //
    max-width: 100%;
    //max-height: 59rem;
  }
  .payment-input {
    font-size: 1.5rem;
  }
  .payment-content-inner {
    &.md,
    &.sm {
      //margin-bottom: 7.5%;
      padding-bottom: 7.5%;
    }
    overflow-x: hidden;
    overflow-y: auto;
  }
  .payment-head {
    padding: 1.8rem 2.4rem;
    margin-bottom: 3.6rem;
    border-bottom: 0.1rem solid rgba(#100b28, 0.26);
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    left: 0;
    background-color: #ffffff;
    z-index: 100;
  }
  .payment-title {
    text-align: left;
    padding-right: 6rem;
    font-size: 2rem;
    line-height: 2.5rem;
  }
  .payment-popup-close {
    position: absolute;
    right: 0.6rem;
    top: 0.6rem;
    bottom: 0.6rem;
    margin: auto 0;

    &:hover {
      background-color: rgba(#100b28, 0.08);
      color: rgba(var(--background), 1);
    }
  }
  .payment-inputs {
    margin-top: 3.1rem;
    max-height: 100% !important;
  }
  .insufficient-founds {
    left: 0;
    top: -4px;
    position: absolute;
    pointer-events: none;
    width: 100%;
    height: 100% !important;

    > h3 {
      opacity: 0;
      transition: opacity 240ms;
      font-size: 1.6rem;
      line-height: 2.4rem;
      margin-bottom: 1.2rem;
      width: 60%;
      margin-left: auto;
      margin-right: auto;
    }

    > p {
      opacity: 0;
      transition: opacity 240ms;
      font-size: 1.4rem;
      line-height: 2.4rem;
      width: 209px;
    }
  }
  .payment-label-text {
    font-size: 1.3rem;
    line-height: 1.6rem;
  }
  .payment-label {
    padding-left: 2.4rem;

    &.has-margin {
      margin-left: 2.5rem;
      padding-left: 0;
    }
  }
  .payment-input-row {
    padding: 0 2.4rem 0 2.4rem;

    &:not(:last-child) {
      margin-bottom: 3.6rem;
    }
  }
  .payment-bottom {
    padding: 0 2.4rem 2.4rem 2.4rem;
  }
  .payment-bottom-inner {
    padding-bottom: 0;
  }
  .payment-b-row {
    &.bold {
      margin-bottom: 2.3rem;
    }
  }
  // payment options END
  // confirmation START
  .confirmation-title {
    font-size: 1.4rem;
    line-height: 2.4rem;
    margin-bottom: 1.8rem;
  }
  .submitted-block {
    padding: 2rem;
    min-height: 17.4rem;

    &:before {
      border-width: 0.4rem;
      left: -0.4rem;
      top: -0.4rem;
    }
  }
  .submitted-row-info {
    font-size: 1.2rem;

    &:not(:last-child) {
      margin-bottom: 0.8rem;
    }
  }
  .submitted-price-row {
    height: 4.8rem;
    margin-bottom: 1.4rem;
  }
  .submitted-row-info-text {
    font-size: 1.2rem;
  }
  .go-back-listing {
    width: 100%;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap;
    font-size: 1.6rem;
    line-height: 2rem;
    border-top: 3.2rem solid transparent;

    > p {
      margin-bottom: 0;
    }

    > i {
      font-size: 2.4rem;
      margin-left: 0.8rem;
    }
  }
  .pdf-download {
    margin: 0 auto;
  }
  // confirmation END
  // preview your ad START
  .step-name-container {
    left: 0;
  }
  .preview-your-ad-content-column-row-cnt {
    .review-copy-radio-buttons-row {
      padding-top: 2rem;
      position: absolute;
      top: 7rem;
      left: 0;
      text-align: initial;
    }

    .review-copy-radio-form {
      max-width: 100%;
    }

    .review-copy-radio-text {
      font-size: 1.2rem;
      line-height: 1.6rem;
    }
  }
  .preview-your-ad-content {
    display: block;
    padding-top: 16.1rem;
    text-align: center;

    .step-name-container {
      display: block;
      height: 4rem;
      text-align: center;
      margin-top: -2rem;
    }
    .step-name {
      color: rgba(var(--background), 1);
      font-size: 1.4rem;
      font-weight: normal;
    }
  }
  .review-copy-radio-buttons-column {
    &:not(:last-child) {
      margin-bottom: 1.2rem;
      margin-right: 1rem;
    }
  }
  .preview-your-ad-menu {
    font-size: 1.1rem;
  }
  .preview-your-ad-block {
    display: inline-block;
    max-width: 23.3rem;
    //pointer-events: none;
  }
  // carousel START
  .preview-your-ad-popup-card {
    //height: 40rem;
    .p-y-ad-swipe-icon {
      &.right {
        right: -0.8rem;
      }

      &.left {
        left: -0.8rem;
      }
    }
    .preview-your-ad-swipe-block {
      max-width: 28rem;
    }
    .preview-your-ad-holder {
      width: 26rem;
    }
  }
  .preview-your-ad-info-text {
    font-size: 1.4rem;
    line-height: 2.4rem;
  }
  .preview-your-ad-info-text-hidden {
    display: none;
  }
  .preview-your-ad-slider-row {
    padding-bottom: 2rem;
    border-left: 0.9rem solid transparent;
    border-right: 0.9rem solid transparent;
  }
  .preview-your-ad-block-head {
    padding: 0.8rem;
  }
  .preview-your-ad-logo-block {
    width: 2.8rem;
    height: 2.8rem;
    margin-right: 0.6rem;
  }
  .preview-your-ad-user-name {
    font-size: 0.9rem;
    line-height: 1;
  }
  .preview-your-ad-sponsored-text {
    display: flex;
    align-items: center;
    font-size: 0.8rem;
    line-height: 1.2rem;

    > i {
      font-size: 0.8rem;

      &:after {
        top: 0;
        bottom: 0;
        margin: auto 0 auto 0.6rem;
      }
    }
  }
  .preview-your-ad-block-info {
    font-size: 1.1rem;
    line-height: 1.3rem;
    margin-bottom: 1rem;
    height: 2.5rem;
    padding: 0 0.8rem;
  }
  .slider-arrow {
    width: 2.4rem;
    height: 2.4rem;
    font-size: 1rem;
  }
  .your-ad-slider-row {
    border-left: 0.9rem solid transparent;
    border-right: 0.9rem solid transparent;
  }
  .preview-your-ad-card {
    width: 17rem;
    height: 22.641rem;
    margin-right: 0.9rem;
    &.full {
      .preview-your-ad-card-inner {
        padding: 1.7rem 0;
      }
    }
    &.has-header {
      .preview-your-ad-card-content {
        border-left: 0.6rem solid transparent;
        border-right: 0.6rem solid transparent;
      }
      .preview-your-ad-card-img {
        height: calc(100% + 0.8rem);
        transform: translate(0, -1.4rem);
        transform-origin: bottom;
      }
    }
  }
  .preview-your-ad-card-img {
    min-height: 12rem;
  }
  .preview-your-ad-card-inner {
    &:before {
      height: 2.2rem;
    }
  }
  .preview-your-ad-card-head-logo {
    width: 2.6rem;
    height: 2.6rem;
    margin-left: 1rem;
  }
  .preview-your-ad-card-head {
    height: 5.4rem;
    padding: 0.6rem 0.8rem 2.6rem 0.8rem;
  }
  .preview-your-ad-card-user {
    font-size: 1rem;
    line-height: 1.2rem;
  }
  .preview-your-ad-card-user-info {
    font-size: 0.7rem;
    line-height: 1.1rem;
  }
  .preview-your-ad-card-bottom-column {
    > p {
      font-size: 1rem;
      line-height: 1.3rem;
      max-height: 2.6rem;
    }

    > span {
      font-size: 1rem;
      line-height: 1.4rem;
    }
  }
  .preview-your-ad-content-column-row {
    justify-content: center;
  }
  .preview-social-tabs-container {
    display: none;
  }
  .p-y-ad-swipe-icon {
    display: block;

    &.right {
      right: -0.8rem;
    }

    &.left {
      left: -0.8rem;
    }
  }
  .preview-your-ad-swiper-row {
    text-align: center;
    height: 40rem;
    width: 100%;
    max-width: 30rem;
    padding: 1rem 1rem 3rem 1rem;
    overflow: hidden;
  }
  .preview-your-ad-swipe-block {
    max-width: 28rem;
    margin: 0 auto;
  }
  .preview-your-ad-holder {
    width: 28rem;
    margin-right: 4rem;
  }
  .preview-your-ad-swipe-block-inner {
    text-align: initial;
  }
  .preview-your-ad-content-column {
    &:first-child {
      display: inline-block;
      vertical-align: top;
      width: 100%;
      max-width: 30rem;
      margin: 0 auto;
      position: relative;
    }
  }
  .preview-your-ad-content-column-button {
    max-width: 27.2rem;
    width: 100%;
    flex-direction: row;
  }
  .preview-your-ad-card-button {
    min-width: 6.2rem;
    height: 2.5rem;
    font-size: 0.9rem;
    border-radius: 0.6rem;
  }
  .preview-your-ad-card-bottom {
    padding: 0 0.8rem;
    height: 5.3rem;
  }
  .preview-your-ad-block-bottom-column {
    > span {
      font-size: 0.8rem;
    }

    > i {
      font-size: 1rem;
    }
  }
  .preview-your-ad-block-bottom-inner {
    padding: 1.1rem 0;
  }
  .preview-your-ad-popup-close {
    top: 1.4rem;
    right: 1.4rem;
  }
  .your-ad-details-head-link {
    display: none;
  }
  // carousel END
  // single image START
  .preview-your-ad-block {
    &.single-image {
      width: 20.6rem;
      .preview-your-ad-logo-block {
        width: 2.6rem;
        height: 2.6rem;
        margin-right: 0.4rem;
      }
      .preview-your-ad-user-name {
        font-size: 0.8rem;
        line-height: 0.9rem;
      }
      .preview-your-ad-sponsored-text {
        font-size: 0.8rem;
        line-height: 0.9rem;
        > i {
          font-size: 0.7rem;
        }
      }
      .preview-your-ad-menu {
        font-size: 1.1rem;
        transform: none;
      }
      .preview-your-ad-block-bottom-column {
        transform: translate(-6px, 1px);
      }
      .preview-your-ad-block-head {
        padding: 0.8rem;
      }
      .preview-your-ad-slider-row {
        padding-bottom: 0.8rem;
        border: 0;
      }
      .preview-your-ad-block-info {
        font-size: 0.9rem;
        line-height: 1.2rem;
        height: 2.3rem;
        margin-bottom: 0.4rem;
        padding: 0 0.8rem;
      }
      .preview-your-ad-card {
        max-width: 26rem;
        height: 25.7rem;
        .preview-your-ad-card-bottom {
          padding: 0 0.8rem;
        }
        &.has-header {
          .preview-your-ad-card-content {
            border-left: 1rem solid transparent;
            border-right: 1.1rem solid transparent;
          }
          .preview-your-ad-card-img {
            height: calc(100% + 1rem);
            transform: translate(0, -2rem);
          }
        }
      }
      .preview-your-ad-block-bottom-inner {
        padding: 0.6rem 0 0.4rem 0;
      }
      .preview-your-ad-card-button {
        min-width: 6.3rem;
        height: 2.3rem;
        font-size: 0.9rem;
        line-height: 1.2rem;
      }
      .preview-your-ad-card-bottom-column {
        display: flex;
        flex-direction: column-reverse;

        > span {
          font-size: 0.7rem;
        }

        > p {
          font-size: 1rem;
          line-height: 1.2rem;
          max-height: 2.4rem;
        }
      }
      .preview-your-ad-card-img {
        min-height: 14.7rem;
      }
      .preview-your-ad-card-head-logo {
        width: 3rem;
        height: 3rem;
      }
      .preview-your-ad-card-head {
        height: 6.5rem;
        padding: 1rem 1rem 3rem 1rem;
      }
      .preview-your-ad-card-user {
        font-size: 1rem;
        line-height: 1.7rem;
        margin-bottom: 0;
      }
      .preview-your-ad-card-user-info {
        font-size: 0.7rem;
        line-height: 1.1rem;
      }
    }
  }
  // single image END
  // instagram START
  .preview-your-ad-popup-card {
    .instagram-your-ad-block {
      width: 24rem;
    }
  }
  .instagram-your-ad-block {
    width: 24rem;
    display: inline-block;
    .preview-your-ad-card-head {
      height: 7.6rem;
      padding: 0.6rem 0.8rem 2.6rem 0.8rem;
    }
    .preview-your-ad-card {
      height: 24.128rem;
      &.has-header {
        .preview-your-ad-card-content {
          border-left: 1rem solid transparent;
          border-right: 1rem solid transparent;
        }
        .preview-your-ad-card-img {
          height: calc(100% + 1rem);
          transform: translate(0, -2.1rem);
        }
      }
    }
    .preview-your-ad-card-head-logo {
      width: 3.6rem;
      height: 3.6rem;
    }
    .preview-your-ad-card-user {
      font-size: 1.3rem;
      line-height: 1.5rem;
      margin-bottom: 0;
    }
    .preview-your-ad-card-user-info {
      font-size: 0.8rem;
      line-height: 2rem;
    }
  }
  .instagram-your-ad-head {
    min-height: 3rem;
    padding: 0.6rem 1.2rem;
  }
  .instagram-your-ad-head-texts {
    > p {
      font-size: 1.1rem;
      line-height: 1.4rem;
    }

    > span {
      font-size: 1rem;
      line-height: 1.3rem;
    }
  }
  .instagram-shop-now {
    margin-bottom: 0.6rem;
  }
  .instagram-your-ad-logo {
    height: 2.2rem;
  }
  .instagram-your-ad-icons {
    padding-bottom: 0.4rem;
  }
  .instagram-shop-now-inner {
    padding: 0.7rem 0;

    > span {
      font-size: 0.9rem;
      line-height: 1.2rem;
    }

    > i {
      font-size: 0.8rem;
    }
  }
  .instagram-your-ad-icon {
    font-size: 1.3rem;
  }
  .instagram-info-row {
    font-size: 0.9rem;
    line-height: 1.2rem;
    max-height: 2.4rem;
  }
  .instagram-your-ad-bottom {
    padding: 0 0.8rem 0.8rem 0.8rem;
  }
  .instagram-your-ad-icons-column {
    grid-gap: 1rem;
  }
  // instagram END
  // story START
  .preview-your-ad-popup-card {
    .story-insta-your-ad-block {
      .preview-your-ad-card {
        &.popup-image {
          height: 19rem;
        }
      }
    }
  }
  .story-insta-your-ad-block {
    margin: 0 auto;
    width: 20.3rem;
    display: inline-block;
    .preview-your-ad-card {
      height: 20.417rem;

      &.has-header {
        .preview-your-ad-card-content {
          border-left: 0.9rem solid transparent;
          border-right: 0.9rem solid transparent;
        }
        .preview-your-ad-card-img {
          height: calc(100% + 1.885rem);
          transform: translate(0, -1.9rem);
        }
      }
    }
    .preview-your-ad-card-head {
      height: 6.6rem;
      align-items: flex-start;
    }
    .preview-your-ad-card-user {
      font-size: 1rem;
      line-height: 1.4rem;
      margin-bottom: 0;
    }
    .preview-your-ad-card-user-info {
      font-size: 0.7rem;
      line-height: 1.2rem;
    }
    .preview-your-ad-card-head-logo {
      width: 3rem;
      height: 3rem;
    }
  }
  .story-insta-ad-logo {
    width: 1.8rem;
    height: 1.8rem;
  }
  .story-insta-time-row {
    margin-bottom: 0.6rem;
  }
  .story-insta-your-ad-head-inner {
    padding: 0 0.3rem;
  }
  .story-insta-your-ad-head {
    height: 5rem;
  }
  .story-insta-your-ad-button-row {
    > i {
      font-size: 0.8rem;
      padding-bottom: 0.1rem;
    }

    > button {
      min-width: 5.1rem;
      height: 2.4rem;
      font-size: 0.7rem;
    }
  }
  .story-insta-ad-head-texts {
    > p {
      font-size: 0.7rem;
      line-height: 0.7rem;
    }

    > span {
      font-size: 0.6rem;
      line-height: 0.7rem;

      > i {
        font-size: 0.4rem;
      }
    }
  }
  .story-insta-your-ad-icons-column {
    grid-gap: 0.8rem;

    > i {
      font-size: 0.8rem;
    }
  }
  .story-insta-your-ad-bottom {
    height: 10.6rem;
    padding: 1.1rem 2.2rem 0.8rem 2.2rem;
  }
  .story-insta-your-ad-bottom-text {
    font-size: 0.9rem;
    line-height: 1.2rem;

    & > span {
      max-height: 2.4rem;
      overflow: hidden;
    }
  }
  // story END
  // preview your ad END
  // no result START
  .no-result {
    position: relative;
    height: auto;
    padding: 2rem 2.6rem 3rem;
  }
  .no-result-img {
    max-width: 7.2rem;
    max-height: 7.2rem;
    margin-bottom: 1.2rem;
  }
  .no-result-title {
    font-size: 2.2rem;
    line-height: 2.8rem;
  }
  .no-result-info {
    font-size: 1.5rem;
    line-height: 2.5rem;
  }
  // no result END
  // analytics START
  .analytics-page {
    height: auto;
    min-height: 100%;
  }
  .analytics-menu {
    top: 2rem;
    right: 1.6rem;
  }
  .analytics-menu-open-checkbox {
    top: 1.7rem;
    right: 1.5rem;
  }
  .your-listing-analytics-row-c-buttons,
  .back-to-all-ads-mobile-buttons {
    padding: 1.6rem;
    top: 5.3rem;
  }
  .your-listing-analytics-row {
    //height: 8rem;
    height: auto;
    min-height: 8rem;
  }
  .has-rm-manager {
    .analytics-active-listing-button.tablet-button {
      display: none;
    }
  }
  .analytics-active-listing-button {
    flex: 1;
    padding: 0 0.5rem;
    min-width: 60%;
    font-size: 1.5rem;
  }
  .your-listing-analytics-row-inner {
    display: block;
    &.has-rm-manager {
      display: flex;
      justify-content: space-between;
      flex-wrap: nowrap;
    }
  }
  .your-listing-analytics-name {
    font-size: 2.2rem;
    line-height: 2.8rem;
    margin-bottom: 0.6rem;
  }
  .your-listing-analytics-address {
    font-size: 1.3rem;
    line-height: 1.6rem;
  }
  .analytics-page-datepicker-column {
    margin-top: 2.4rem;
  }
  .analytics-page-table-content-scrollable {
    position: relative;
    overflow: unset;
    height: auto;
    min-height: 100%;
  }
  .analytics-page-tabs-column {
    grid-column-gap: 1.6rem;
  }
  .analytics-page-tab {
    font-size: 1.4rem;
    line-height: 1.8rem;
  }
  .analytics-page-table-head {
    flex-direction: column;
    align-items: flex-start;
    padding: 2rem;
    border: 0.1rem solid #efefef;
  }
  .choose-listing-content-tabs-column {
    grid-column-gap: 2rem;
  }
  .client-finder-notification {
    display: block;
    font-size: 1.3rem;
    padding: 1rem;
  }
  .analytics-page-promote-btn {
    width: 100%;
    height: 3.2rem;
    font-size: 1.5rem;
    letter-spacing: 0;
  }
  .analytics-page-table-head-name {
    font-size: 1.7rem;
    line-height: 2.2rem;
  }
  .analytics-page-table-head-promotionBtn-wrapper {
    max-width: unset;

    & button {
      height: 32px;
    }
  }
  .analytics-page-table {
    padding: 0;
  }
  .analytics-page-table-row {
    padding: 0 2rem;
    height: auto;
    transform: translate3d(0, 0, 0);

    &.head {
      .goal {
        display: flex;
      }

      .analytics-page-table-column {
        width: auto;
        margin: 0;

        &:nth-child(2) {
          & ~ .analytics-page-table-column {
            display: none;
          }
        }
      }
    }

    &.total {
      .analytics-page-table-column {
        margin: 0;

        &.spend {
          position: absolute;
          right: 2rem;
          top: 0;
          bottom: 0;
          margin: auto 0;
        }

        &:empty {
          display: none !important;
        }
      }
    }

    &.head,
    &.total {
      padding: 0 2rem;
    }

    &.for-mobile {
      .analytics-page-table-row-inner {
        padding-top: 2rem;
        overflow: hidden;
        display: block;

        &:before,
        &:after {
          content: "";
          position: absolute;
          border-radius: 50%;
        }

        &:before {
          width: 2rem;
          height: 2rem;
          right: 2rem;
          top: 2rem;
          background-color: #ffffff;
          box-shadow: 0 0.3rem 0.6rem rgba(#000000, 0.17);
        }

        &:after {
          width: 1.2rem;
          height: 1.2rem;
          background-color: rgba(var(--brand-color), 1);
          top: 2.4rem;
          right: 2.4rem;
        }
      }

      .analytics-page-table-column {
        width: auto;
        margin: 0;

        &:first-child {
          display: block;
          padding-bottom: 2rem;
        }

        &:not(:first-child) {
          display: inline-block;
          vertical-align: top;
          width: 50%;
          margin-bottom: 1.2rem;
        }

        &.see-details,
        &.cancel-ad {
          display: block;
          width: 100%;
        }

        &.start,
        &.end {
          display: none;
        }

        &.goal,
        &.spend,
        &.status {
          &:before {
            display: block;
            color: rgba(#100b28, 0.56);
            letter-spacing: 0.5px;
            text-transform: uppercase;
            font-size: 1rem;
            line-height: 1.7rem;
          }
        }

        &.goal {
          &:before {
            content: "goal";
          }
        }

        &.spend {
          &:before {
            content: "spend";
          }
        }

        &.status {
          &:before {
            content: "status";
          }
        }
      }
    }

    &.finished {
      .analytics-page-table-row-inner {
        &:after {
          background-color: rgba(var(--background), 1);
        }
      }
    }
  }
  .analytics-page-table-column-text {
    font-size: 1.3rem;
    line-height: 1.6rem;
  }
  .cancel-the-ad-button {
    display: none;
  }
  .cancel-the-ad-content {
    position: static;
    opacity: 1;
    box-shadow: none;
    background: none;
    padding: 1.4rem 0 1rem 0;
    pointer-events: auto;
  }
  .cancel-the-ad-content-head {
    display: none;
  }

  .cancel-the-ad-popup {
    display: block;
    z-index: 999;
    padding: 0;
  }
  .cancel-the-ad-popup-content {
    border-radius: 0;
    padding: 4.5rem;
  }
  .cancel-the-ad-popup-title {
    font-size: 2rem;
    line-height: 2.6rem;
  }
  .cancel-the-ad-popup-text {
    font-size: 1.6rem;
  }
  .analytics-page-table-row-inner {
    height: 5.6rem;
    justify-content: space-between;
    transition: height 240ms ease-in-out;
  }
  .analytics-page-table-row-open {
    pointer-events: auto;
  }
  .analytics-page-table-column {
    flex: 0 0 auto;
    height: auto;
  }
  .analytics-page-table-dropdown {
    position: static;
  }
  .analytics-page-table-dropdown-content {
    top: 4.8rem;
    position: absolute;
    width: 100% !important;
    left: 0;
  }
  .analytics-active-listing-button-mobile {
    display: block;
  }
  .see-details-button {
    > span {
      display: inline-block;
    }
  }
  .analytics-page-tabs-datepicker-row {
    padding: 1.6rem 0 2.4rem 0;
  }
  .datepicker-text {
    font-size: 1.4rem;
    padding: 0;

    span {
      display: none;
    }
  }
  .analytics-page-content {
    padding: 0 1.6rem;
    overflow: hidden;
  }
  // analytics END
  // your ad Details START
  .your-ad-details {
    height: auto;
    min-height: 100%;
  }

  .your-ad-details-head {
    padding: 2rem 1.6rem 1.6rem 1.6rem;
  }
  .your-ad-details-content {
    padding: 0 1.6rem;
  }
  .your-ad-details-container {
    height: auto;
  }
  .your-ad-details-content-inner {
    display: block;
  }
  .your-ad-details-head-inner-column {
    &:first-child {
      padding-right: 0;
    }
  }
  .your-ad-details-head-info {
    display: block;
  }
  .your-ad-details-head-info-title {
    font-size: 1.2rem;
    line-height: 1.5rem;
    margin-bottom: 0.6rem;
  }
  .your-ad-details-head-info-title-text {
    font-size: 1.5rem;
    line-height: 1.9rem;
  }
  .your-ad-details-head-address {
    font-size: 2rem;
    line-height: 2.4rem;
    margin-bottom: 2rem;
  }
  .add-preview-hide-mobile {
    display: block;
    margin: 0 0 1rem 0;
  }
  .your-ad-details-head-info-column {
    display: inline-flex;
    min-height: initial;
    &:first-child {
      width: 100%;
      margin-bottom: 2rem;
      margin-right: 0;
    }
  }
  .your-ad-details-container-scrollable {
    position: relative;
    overflow: unset;
  }
  .your-ad-details-head-button {
    width: 100%;
    margin-right: 1rem;
    font-size: 1.5rem;
  }
  .your-ad-details-info-column {
    padding: 2rem !important;
    min-height: 11.2rem;

    .your-ad-details-min-info-content {
      left: auto;
      transform: none;
      right: -2.6rem;

      &:before {
        left: auto;
        margin: 0;
        right: 2.5rem;
      }
    }
  }
  .your-ad-details-info-row {
    grid-auto-flow: row;
    margin-bottom: 1.6rem;
    grid-gap: 1.6rem;
  }
  .reach-and-clicks {
    display: block;
  }
  .reach-and-clicks-title {
    font-size: 1.8rem;
    line-height: 2.3rem;
    margin-bottom: 0.4rem;
  }

  .reach-and-clicks-text {
    font-size: 1.4rem;
    line-height: 2rem;
  }
  .reports-content-table-text {
    font-size: 1.4rem;
    line-height: 1.8rem;
  }
  .your-ad-details-point {
    padding-top: 0.4rem;
    font-size: 3.6rem;
    line-height: 4rem;
  }
  .your-ad-details-min-info {
    right: 0;
    top: 0;
    left: auto;
  }
  .your-ad-details-min-title {
    font-size: 1.6rem;
    line-height: 2rem;
  }
  .map-chart-head {
    padding: 2.2rem 2rem 2rem 2rem;
  }
  .map-chart-content-column {
    &.chart-column {
      height: auto;
      padding: 2rem;
    }
  }
  .reports-row {
    grid-gap: 2.4rem;
  }
  .reports-head {
    padding: 2.1rem 2rem 3.3rem;
  }
  .map-chart {
    height: 60vmin;

    > div > div {
      height: auto !important;
    }

    svg {
      height: auto;
      width: 100%;
      margin: 0 auto;
      display: block;
    }
  }
  .share-metrics-hidden-button-mobile {
    display: block;
  }
  .staked-bar-chart {
    height: 26.5rem;
    padding: 0 2rem 1rem;
  }
  .map-chart-table-row {
    height: 4rem;

    &.head {
      height: 4.4rem;
      position: static;
    }
  }
  .map-chart-content-column {
    &.table-column {
      max-height: 330px;
    }
  }
  .map-chart-table {
    padding-right: 0;
    display: grid;
    grid-template-columns: 20rem 11fr;
  }
  .map-chart-dots-mobile {
    display: flex;
  }
  .map-chart-row {
    margin-bottom: 2.4rem;
  }
  .map-chart-table-cs {
    &:first-child {
      width: 100%;
      .map-chart-table-row {
        padding-left: 1.6rem;
      }
    }
    &:last-child {
      overflow-y: hidden;
      overflow-x: auto;
      .map-chart-table-row {
        width: 38rem;
        padding-right: 2rem;
      }
      .map-chart-table-column {
        display: flex;
        align-items: center;
        justify-content: flex-end;
      }
    }
  }
  .map-chart-table-text {
    font-size: 1.4rem;
    line-height: 1.8rem;
  }
  .reports-column {
    position: relative;
    max-height: 100%;
  }
  .reports-head-column {
    &:nth-child(2) {
      position: absolute;
      bottom: 3rem;
      left: 2rem;
    }
  }
  .reports-content-table-row {
    padding: 0 2rem;
    margin-bottom: 1rem;

    &.head {
      height: 4.5rem;
    }
  }
  .reports-content-table-total {
    padding: 1.7rem 2rem 2.1rem;
  }
  .reports-content-table-scrollable {
    height: 29.9rem;
  }
  .user-action {
    margin-top: 3.2rem;
  }
  // permission issue START
  .permission-issue {
    padding: 0 2.4rem 2.4rem;
  }
  .permission-issue-head {
    padding-bottom: 3.8rem;
    margin-bottom: 3.3rem;
  }
  .permission-issue-img {
    max-width: 23.2rem;
  }
  .permission-issue-title {
    font-size: 2.8rem;
    line-height: 3.6rem;
    margin-bottom: 1.2rem;
  }
  .permission-issue-shadow {
    width: 140px;
    border-radius: 40%;
    margin: 3.4rem 0;
  }
  .permission-issue-text {
    font-size: 1.6rem;
    line-height: 2.5rem;
  }
  .permission-issue-b-text {
    font-size: 1.5rem;
    line-height: 2.3rem;
    padding: 0 2rem;
  }
  // permission issue END
  // your ad Details END

  // listing traffic start
  .container {
    .pie-chart {
      width: 220px;
      height: 220px;
      margin-right: auto;
      margin-left: auto;
    }

    .traffic-card {
      &-body {
        flex-direction: column;
        padding: 20px 0 20px;
      }

      .pie-list {
        width: 100%;

        .list-item {
          padding-right: 20px;
          width: 100%;
        }
      }

      .pie-chart-info-container {
        width: 192px;
        height: 192px;
      }

      .pie-chart-info-title {
        font-size: 3.2rem;
        line-height: 4.1rem;
      }

      .pie-chart-info-subtitle {
        line-height: 1.4rem;
        font-size: 1.1rem;
      }
    }
  }
  .xs-hidden {
    display: none;
  }
  .back-button {
    max-width: unset;
  }
  // listing traffic END
  .pie-list {
    max-height: 17.5rem;
  }

  //Blank Popup START
  .blank-popup {
    display: block;
    z-index: 999;
    padding: 0;
  }
  .blank-popup-content {
    border-radius: 0;
  }
  //Blank Popup END
  .blank-popup-close {
    top: 0.8rem;
    right: 0.7rem;
  }
}
@media screen and (max-width: 767px) {
  .listing-page-search {
    font-size: 1.4rem;
  }
  .listing-page-search-icon {
    margin-right: 0.8rem;
  }
}
@media screen and (max-width: 813px) and (orientation: landscape) {
  .scrollable-container {
    position: relative;
    overflow: unset;
  }
}
// Mobile END
@media screen and (max-width: 1439px) {
  .copyright-info {
    right: 3.2rem;
    bottom: 3.2rem;
  }
  .choose-listing-head-images-column {
    width: 5.6rem;
    height: 5.6rem;

    &:not(:last-child) {
      margin-right: 0.8rem;
    }
  }
  .choose-listing-images-view-step {
    .steps-count-column {
      display: none;
    }
  }
}
@media (max-width: 767px) {
  .choose-listing-head-images-column {
    width: 4.8rem;
    height: 3.6rem;

    &:not(:last-child) {
      margin-right: 0.8rem;
    }
  }
  .choose-listing-head-choose-column {
    gap: 1.7rem;
  }
}
